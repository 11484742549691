import React from "react"
import { X, ChevronRight, ChevronLeft, Minus, Plus, Check, AlertTriangle, MessageCircle } from "react-feather"

import {
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody
} from "reactstrap"
import Flatpickr from "react-flatpickr";
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import moment from "moment"
import { calcTime, secondsToTime } from "../../../utility/timeLegend"
import { TimerUtils } from "../../../utility/TimerConvertUtils"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sendMessage } from '../../../redux/actions/chat'
import { ToastSuccess, ToastError } from '../../../utility/ToastMessages'
import InputMask from "react-input-mask"
import { timeround } from '../../../utility/timeround'

import "../../../assets/scss/components/gantt.scss"
import { ThemeProvider } from "styled-components";
import { timers } from "jquery";
import PerfectScrollbar from "react-perfect-scrollbar"
import TaskInactivityJustification from "./components/TaskInactivityJustification";

function getPosition(element) {
    var xPosition = 0;
    var yPosition = 0;

    while (element) {
        xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition };
}

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"]
//const time = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0]


class Gantt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendMessage: null,
            contestTime: null,
            message: '',
            justification: '',
            modalInfo: null,
            itemWidth: 0,
            justifications: [],
            splitTimes: [],
            tooltipOpen: null,
            scrollLeft: 0,
            confirmContestation: false,
            feedbackMessage: {
                color: null,
                message: null
            },
            width: null,
            height: null,
            modals: {
                taskInactivityJustifications: false,
                taskInactivityJustificationsDay: false
            }
        }
        this.myRef = React.createRef()
        this.origin = React.createRef()
        this.modal = React.createRef()
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this)
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentWillMount() {
        this.props.onRef(null)
    }
    toggleTooltip = (tooltip) => {
        this.setState(prevState => ({
            tooltipOpen: prevState.tooltipOpen === tooltip ? null : tooltip
        }))
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }




    onScroll = () => {
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = this.myRef.current.scrollTop
        const origin = this.origin.current
        this.setState({
            scrollLeft: this.myRef.current.scrollLeft
        })
    }

    onMouseEnter = (e, item, i, m, idleTime, difTime, totalTime, feedbackMessage) => {
        const x = e.clientX;
        const y = e.clientY;
        const pos = getPosition(e.target)
        const { offsetWidth } = e.target
        const splitTime = idleTime > 0 ? 0 + secondsToTime(idleTime) : ''
        var body = document.body, html = document.documentElement;
        var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);
        //const modalSize = item.status === "A" ? 112 : this.props.admin ? 303 : 265
        const modalSize = this.props.admin ? 303 : 265
        this.setState(prevState => ({
            splitTimes: {
                ...prevState.splitTimes,
                [`time_${item.code}`]: splitTime && splitTime.length === 4 ? `0${splitTime}` : splitTime.length === 5 ? `${splitTime}` : prevState.splitTimes[`time_${item.code}`]
            },
            itemWidth: offsetWidth,
            modalInfo: {
                item,
                idleTime,
                difTime,
                totalTime
            },
            //clientX: modal.offsetWidth + x > window.innerWidth ? (x - modal.offsetWidth) : x,
            clientY: pos.y + modalSize + 30 >= height ? pos.y - window.pageYOffset - modalSize : pos.y - window.pageYOffset + 25,
            clientX: pos.x

        }))
        /*const modal = this.modal.current
        this.setState({
            //clientX: e.clientX,
            clientX: modal.offsetWidth + x > window.innerWidth ? (x - modal.offsetWidth) : x,
            clientY: e.clientY,
            modalInfo: {
                item,
                index,
                line: data.line,
                position: item.position
            }
        })*/
    }

    handleClickItem = (item, idleTime, difTime, totalTime) => {
        if (this.state.width < 728) {
            const splitTime = idleTime > 0 ? 0 + secondsToTime(idleTime) : ''
            this.setState(prevState => ({
                splitTimes: {
                    ...prevState.splitTimes,
                    [`time_${item.code}`]: splitTime && splitTime.length === 4 ? `0${splitTime}` : splitTime.length === 5 ? `${splitTime}` : prevState.splitTimes[`time_${item.code}`]
                },
                modalInfo: {
                    item,
                    idleTime,
                    difTime,
                    totalTime
                },

            }))
        }
    }
    makeModalFixed = () => {
        this.setState({
            modalFixed: true
        })
    }
    onMouseLeave = () => {
        console.log('mouse leave')
        this.taskInactivityJustification()
        // if (!this.state.modalFixed) {
        //     this.setState({
        //         clientX: 0,
        //         clientY: 0,
        //         modalInfo: null,
        //         sendMessage: null,
        //         contestTime: null,
        //         feedbackMessage: null,
        //         message: '',
        //         justification: ''
        //     })
        // }
    }

    handleSendMessage = (info) => {
        this.setState(prevState => ({
            sendMessage: prevState.sendMessage ? false : info,
            message: info.item.description
        }))
    }

    handleContestTime = (info) => {
        this.setState(prevState => ({
            contestTime: prevState.contestTime ? false : info,
        }))
    }

    sendTaskMessage = () => {
        //Codigo destinatário, IsPinned, Text
        if (this.state.message.trim().length === 0) {
            this.setState({
                feedbackMessage: {
                    color: "danger",
                    message: "Insira uma mensagem"
                }
            })
        } else {
            this.setState({
                feedbackMessage: null,
                message: '',
                sendMessage: null
            })
            this.props.sendMessage(this.props.codigo, false, this.state.message)
            ToastSuccess("Mensagem enviada com sucesso")
        }
    }
    selectTimeReprove = (task) => {
        if (this.state.justifications.length === 0 || (this.state.justifications && this.state.justifications[`time_${task.code}`] && this.state.justifications[`time_${task.code}`].trim().length === 0)) {
            this.setState({
                feedbackMessage: {
                    color: "danger",
                    message: "Insira uma mensagem"
                }
            })
        } else {
            this.setState(prevState => ({ confirmContestation: task.code }))
        }
    }

    sendTimeReprove = (task) => {

        const start = moment(task.start).format('YYYY-MM-DD HH:mm')
        const end = moment(task.end).format('YYYY-MM-DD HH:mm')
        const target = `${moment(task.end).format('YYYY-MM-DD')} ${this.state.splitTimes[`time_${task.code}`]}`

        const minutesToReprove = moment(end).diff(moment(target), 'minutes')
        const taskTime = moment(end).diff(moment(start), 'minutes')
        this.setState({
            //confirmContestation: false,
            feedbackMessage: {},
            //splitTimes: [],
            //justifications: []
        })

        if (moment(target).diff(moment(start), 'minutes') < 0 || moment(end).diff(moment(target), 'minutes') < 0) {
            this.setState({
                feedbackMessage: {
                    color: "danger",
                    message: "Verifique o horário digitado"
                }
            })
        } else if (moment(end).diff(moment(target), 'minutes') === 0) {

        } else if (typeof this.state.splitTimes[`time_${task.code}`] === 'undefined' || this.state.splitTimes.length === 0 || this.state.splitTimes[`time_${task.code}`] && this.state.splitTimes[`time_${task.code}`].trim().length === 0) {
            this.setState({
                feedbackMessage: {
                    color: "danger",
                    message: "Insira o tempo a ser reprovado"
                }
            })
        } else {
            if (minutesToReprove === 0) {
                this.setState({
                    feedbackMessage: {
                        color: "danger",
                        message: "Insira o tempo a ser reprovado"
                    }
                })
            } else if (minutesToReprove > taskTime) {
                this.setState({
                    feedbackMessage: {
                        color: "danger",
                        message: `Você pode reprovar no máximo ${secondsToTime(taskTime)}`
                    }
                })
            } else if (minutesToReprove < taskTime) {
                this.setState({
                    confirmContestation: false,
                    justifications: [],
                    splitTimes: [],
                    feedbackMessage: {},
                })
                this.props.handleGanttActions('split', { task, time: minutesToReprove }, this.state.justifications[`time_${task.code}`]).then(res => {
                    console.log(res)
                    console.log(this.state.modalInfo.item)
                    const data = res && res.data && res.data.Tempos && res.data.Tempos.find(f => f.Codigo === this.state.modalInfo.item.code)
                    if (data && data.Codigo) {
                        this.setState(prevState => ({
                            ...prevState,
                            modalInfo: {
                                ...prevState.modalInfo,
                                item: {
                                    ...prevState.modalInfo.item,
                                    status: data.status,
                                    justifications: data.Justificativas,
                                    start: data.data_inicio,
                                    end: data.data_fim
                                }
                            }
                        }))
                    }
                })
            } else {
                this.props.handleGanttActions('reprove', task, this.state.justifications[`time_${task.code}`]).then(res => {
                    console.log(res)
                    console.log(this.state.modalInfo.item)
                    const data = res && res.data && res.data.Tempos && res.data.Tempos.find(f => f.Codigo === this.state.modalInfo.item.code)
                    if (data && data.Codigo) {
                        this.setState(prevState => ({
                            ...prevState,
                            modalInfo: {
                                ...prevState.modalInfo,
                                item: {
                                    ...prevState.modalInfo.item,
                                    status: data.status,
                                    justifications: data.Justificativas
                                }
                            }
                        }))
                    }
                })
            }
        }

        return
        if (this.state.splitTimes && this.state.splitTimes[`time_${task.code}`] && this.state.splitTimes[`time_${task.code}`].trim().replace(/\D/g, '').length < 4) {
            this.setState({
                feedbackMessage: {
                    color: "danger",
                    message: "Verifique o tempo digitado"
                }
            })
        } else {

            const minutesToReprove = TimerUtils.tempoToMinutos(this.state.splitTimes[`time_${task.code}`])
            const taskTime = moment(task.end).diff(moment(task.start), 'minutes')
            if (minutesToReprove === 0) {
                this.setState({
                    feedbackMessage: {
                        color: "danger",
                        message: "Insira o tempo a ser reprovado"
                    }
                })
            } else if (minutesToReprove > taskTime) {
                this.setState({
                    feedbackMessage: {
                        color: "danger",
                        message: `Você pode reprovar no máximo ${secondsToTime(taskTime)}`
                    }
                })
            } else if (minutesToReprove < taskTime) {
                this.setState({
                    confirmContestation: false,
                    justifications: [],
                    splitTimes: []
                })
                this.props.handleGanttActions('split', { task, time: minutesToReprove }, this.state.justifications[`time_${task.code}`])
            } else {
                this.props.handleGanttActions('reprove', task, this.state.justifications[`time_${task.code}`])
            }
        }
    }
    sendTimeContestation = (task) => {

        if (typeof this.state.justifications === 'undefined' || this.state.justifications.length === 0 || this.state.justifications[`time_${task.code}`].trim().length === 0) {
            this.setState({
                feedbackMessage: {
                    color: "danger",
                    message: "Insira uma mensagem"
                }
            })
        } else {

            this.setState(prevState => ({
                justifications: {
                    ...prevState.justifications,
                    [`time_${task.code}`]: ''
                },
                feedbackMessage: {},
            }))

            this.props.handleGanttActions('contestation', task, this.state.justifications[`time_${task.code}`]).then(res => {
                const data = res && res.data && res.data.Tempos && res.data.Tempos.find(f => f.Codigo === this.state.modalInfo.item.code)
                if (data && data.Codigo) {
                    this.setState(prevState => ({
                        ...prevState,
                        modalInfo: {
                            ...prevState.modalInfo,
                            item: {
                                ...prevState.modalInfo.item,
                                status: data.status,
                                justifications: data.Justificativas
                            }
                        }
                    }))
                }
            })
        }
    }

    sendTimeApproval = (task) => {
        //Codigo destinatário, IsPinned, Text

        this.props.handleGanttActions('approval', task, null).then(res => {
            console.log(res)
            const data = res && res.data && res.data.Tempos && res.data.Tempos.find(f => f.Codigo === this.state.modalInfo.item.code)
            if (data && data.Codigo) {
                this.setState(prevState => ({
                    ...prevState,
                    modalInfo: {
                        ...prevState.modalInfo,
                        item: {
                            ...prevState.modalInfo.item,
                            status: data.status,
                            justifications: data.Justificativas
                        }
                    }
                }))
            }
        })
    }
    updateModalInfo = (data) => {
        console.log(this.state)
        console.log(data)
    }
    cleanGanttModalForm = () => {
        //const modalInfo = this.state
        //alert('ijojo')
        /*this.setState({
            modalInfo: null,
            confirmContestation: false,
            feedbackMessage: {},
            splitTimes: [],
            justifications: []
        })*/
    }

    setTimeJustification = (e, code) => {
        //`time_${item.code}`
        const value = e.target.value;
        this.setState(prevState => ({
            justifications: {
                ...prevState.justifications,
                [`time_${code}`]: value
            },
            feedbackMessage: {}
        }))
    }

    setTimeSplit = (e, code) => {
        //`time_${item.code}`
        const value = e.target.value;
        this.setState(prevState => ({
            splitTimes: {
                ...prevState.splitTimes,
                [`time_${code}`]: value
            },
            feedbackMessage: {}
        }))
    }

    taskInactivityJustification = (item) => {
        this.setState(prevState => ({
            ...prevState,
            modals: {
                ...prevState.modals,
                taskInactivityJustifications: prevState.modals.taskInactivityJustifications ? false : item
            }
        }))
    }
    taskInactivityJustificationDay = (day) => {
        this.setState(prevState => ({
            ...prevState,
            modals: {
                ...prevState.modals,
                taskInactivityJustificationsDay: prevState.modals.taskInactivityJustificationsDay ? false : day
            }
        }))
    }

    handleCloseTaskInactivity = () => {
        this.setState(prevState => ({
            ...prevState,
            modals: {
                ...prevState.modals,
                taskInactivityJustifications: false
            }
        }))
    }

    modalDetails = (item, idleTime, difTime, totalTime, feedbackMessage) => {
        const { confirmContestation } = this.state
        const rounded = timeround(item.start, item.end)
        const roundedStart = rounded.start
        const roundedEnd = rounded.end
        return (
            <div className={`${this.state.width < 728 ? 'mobile-modal-container' : ''}`}>
                <div
                    className={`gantt-modal d-flex flex-column  show ${this.state.width < 728 ? 'mobile' : 'desktop'}`}
                    style={this.state.width >= 728 ? { marginLeft: -400 + this.state.itemWidth, top: this.state.clientY, left: this.state.clientX - this.state.scrollLeft } : {}}
                    onMouseLeave={() => this.setState({ modalFixed: false, modalInfo: null, sendMessage: null, Justificativa: '' })}
                    onMouseEnter={() => this.state.taskInactivityJustifications ? this.taskInactivityJustification(item) : null}
                    ref={this.modal}
                //style={{top: `${clientY + 15}px`, left: `${clientX}px`}}
                >
                    <React.Fragment>
                        <div className="modal-chart-bar">
                            <div className={`modal-chart-occuped 
                    ${item.type === 'E' && item.status === 'P' && (item.justifications && item.justifications.filter(f => f.ManagerJustification === null) && item.justifications.filter(f => f.ManagerJustification === null).length === item.justifications.length) ? 'danger' :
                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && !item.justifications[item.justifications.length - 1].ManagerJustification ? `pending dark ${item.split ? 'splited' : ''}` :
                                        item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && item.justifications[item.justifications.length - 1].ManagerJustification ? `pending light ${item.split ? 'splited' : ''}` :
                                            item.type === 'E' && item.status === 'A' && item.split ? 'approved splited' :
                                                item.type === 'E' && item.status === 'A' ? 'approved' :
                                                    //item.type === 'E' && item.status === 'R' && item.split ? 'reproved splited' :  
                                                    (item.type === 'E' && item.status === 'R') || item.status === 'C' ? 'reproved' :
                                                        item.type === 'A' && item.status === 'A' ? 'success' :
                                                            item.type === 'A' && item.status === 'P' ? 'pending' :
                                                                item.type === 'A' && item.status === 'R' ? 'reproved splited' :
                                                                    'none'}`}
                                style={{ width: `${(moment(item.end).diff(moment(item.start), 'minutes') * 100) / totalTime}%` }}
                            >
                            </div>
                            <div
                                className={`modal-chart-percentual ${(moment(item.end).diff(moment(item.start), 'minutes') * 100) / totalTime >= 30 ?
                                    `inside ${item.type === 'A' && item.status === 'R' || item.type === 'E' && item.status === 'R' ? 'darkness' : ''}` :
                                    'outside'}`}
                                style={{
                                    left: `${totalTime === 0 ? 1 :
                                        (moment(item.end).diff(moment(item.start), 'minutes') * 100) / totalTime < 30 ?
                                            ((moment(item.end).diff(moment(item.start), 'minutes') * 100) / totalTime) + 1 : 0}%`,
                                    width: (moment(item.end).diff(moment(item.start), 'minutes') * 100) / totalTime >= 30 ? `${(moment(item.end).diff(moment(item.start), 'minutes') * 100) / totalTime}%` : 'auto'
                                }}>
                                {totalTime > 0 ? ((moment.parseZone(roundedEnd).diff(moment.parseZone(roundedStart), 'minutes') * 100) / totalTime).toFixed(2) : '0'}%
                            </div>
                        </div>
                        <div className="gantt-modal-header d-flex">
                            <div className="d-flex flex-column flex-grow-1">

                                <div className="d-flex">
                                    <div className="flex-grow-1 truncate">
                                        <a href={`/card/${item.cardKey}`}>{item.cardKey}</a> - {item.title}
                                        <div className="duration">
                                            <b>Duração:</b> {moment.parseZone(roundedStart).format('HH:mm')} - {moment.parseZone(roundedEnd).format('HH:mm')} ({secondsToTime(moment.parseZone(roundedEnd).diff(moment.parseZone(roundedStart), 'minutes'))})
                                        </div>
                                    </div>

                                    {this.props.admin &&
                                        <ul className="action-buttons mr-0">
                                            <li onClick={() => this.setState({ sendMessage: true })} className="info cursor-pointer"><MessageCircle /></li>
                                            {item.status !== 'A' && <li className="success cursor-pointer mr-0" onClick={() => this.sendTimeApproval(item)}><Check /></li>}
                                        </ul>

                                    }
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="gantt-idle flex-grow-1" onClick={() => this.taskInactivityJustification(item)}>
                                        <small><b>Produtividade e ociosidade:</b></small>
                                        <div>

                                            {/*<span
                                    className={`modal-chart-occuped ${
                                    //colors[o] === 'red' ? 'danger' : 'success'
                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && !item.justifications[item.justifications.length - 1].ManagerJustification ? 'danger' : 
                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && item.justifications[item.justifications.length - 1].ManagerJustification ? 'pending' : 
                                    item.type === 'E' && item.status === 'A' ? 'approved' :  
                                    item.type === 'E' && item.status === 'R' ? 'reproved' :  
                                    item.type === 'A' && item.status === 'A' ? 'success' : 
                                    'success'
                                    }`}
                                ></span>*/}
                                            <span className="active">
                                                <div className="percentual">{rounded.diff > 0 && idleTime > 0 ? (100 - (idleTime / rounded.diff) * 100).toFixed(2) : "100"}% ativo</div>
                                                <div className="time">{secondsToTime(rounded.diff - idleTime)} ativo</div>
                                            </span>
                                            {<div className="idle-scale">
                                                <div className="scale-active-time" style={{ width: `${(100 - (idleTime / difTime) * 100).toFixed(2)}px` }}>

                                                </div>
                                                <div className="scale-inactive-time" style={{ width: `${((idleTime / difTime) * 100).toFixed(2)}px` }}>

                                                </div>

                                                {/*<div 
                                            className="idle-scale-fragment"
                                            style={{left: `${((idleTime/difTime)*100).toFixed(2)}px`}}>
                                        </div>*/}
                                            </div>}
                                            <span className="inactive">
                                                <div className="percentual">{totalTime > 0 && rounded.diff > 0 ? ((idleTime / rounded.diff) * 100).toFixed(2) : "0"}%  ocioso</div>
                                                <div className="time">{idleTime && secondsToTime(idleTime)}  ocioso</div>
                                            </span>

                                        </div>
                                        {/*idleTime/difTime > 0 &&
                                    <div><span
                                    className={`modal-chart-occuped ${
                                    //colors[o] === 'red' ? 'danger' : 'success'
                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && !item.justifications[item.justifications.length - 1].ManagerJustification ? 'danger' : 
                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && item.justifications[item.justifications.length - 1].ManagerJustification ? 'pending' : 
                                    item.type === 'E' && item.status === 'A' ? 'approved' :  
                                    item.type === 'E' && item.status === 'R' ? 'reproved' :  
                                    item.type === 'A' && item.status === 'A' ? 'success' : 
                                    'success'
                                    }`}
                                ></span> Sem atividade de mouse e teclado: {((idleTime/difTime)*100).toFixed(2)}%</div>
                                */}
                                    </div>
                                    <div className="d-flex flex-column gantt-status">
                                        <small><b>Status:</b></small>
                                        <div>
                                            {/*
                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && !item.justifications[item.justifications.length - 1].ManagerJustification ? <span className="warning">Pendente</span> : 
                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && item.justifications[item.justifications.length - 1].ManagerJustification ? <span className="warning">Pendente</span> : 
                                    item.type === 'E' && item.status === 'A' ? <span className="success">Aprovado</span> :  
                                    (item.type === 'E' && item.status === 'R')|| item.status === 'C' ? <span className="danger">Reprovado</span> :  
                                    item.type === 'A' && item.status === 'A' ? <span className="success">Aprovado</span> : 
                                    <span className="success">Aprovado</span>
                                */}
                                            {
                                                item.type === 'E' && item.status === 'P' && (item.justifications && item.justifications.filter(f => f.ManagerJustification === null) && item.justifications.filter(f => f.ManagerJustification === null).length === item.justifications.length) ? <span className="success">Aprovado</span> :
                                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && !item.justifications[item.justifications.length - 1].ManagerJustification ? <span className="warning">Pendente</span> :
                                                        item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && item.justifications[item.justifications.length - 1].ManagerJustification ? <span className="warning">Pendente</span> :
                                                            item.type === 'E' && item.status === 'A' && item.split ? <span className="success">Aprovado</span> :
                                                                item.type === 'E' && item.status === 'A' ? <span className="success">Aprovado</span> :
                                                                    //item.type === 'E' && item.status === 'R' && item.split ? 'reproved splited' :  
                                                                    (item.type === 'E' && item.status === 'R') || item.status === 'C' ? <span className="danger">Reprovado</span> :
                                                                        item.type === 'A' && item.status === 'A' ? <span className="success">Aprovado</span> :
                                                                            item.type === 'A' && item.status === 'P' ? <span className="warning">Pendente</span> :
                                                                                item.type === 'A' && item.status === 'R' ? <span className="danger">Reprovado</span> :
                                                                                    <span> - </span>
                                            }
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                        <React.Fragment>
                            <div className="gantt-modal-body flex-grow-1">
                                {/*<div className="title">{modalInfo.item.description}</div>*/}
                                {/*<small>#{modalInfo.item.code}</small>*/}
                                <div className="messages">
                                    {/*JSON.stringify(modalInfo.item.justifications)*/}
                                    {item && item.justifications && item.justifications.map((j, i) =>
                                        <div className="message-group" key={`msg_${item.code}_${i}`}>
                                            <span className="date">{moment(j.DataCriacao).format('DD/MM/YY HH:mm')}</span>
                                            {j.Justification &&
                                                <div className="message justify-content-start">
                                                    <div className="message-box left">
                                                        <span className="text">{j.Justification}</span>
                                                    </div>
                                                </div>
                                            }
                                            {j.ManagerJustification &&
                                                <div className="message justify-content-end">
                                                    <div className="message-box right">
                                                        <span className="text">{j.ManagerJustification}</span>
                                                    </div>
                                                </div>
                                            }

                                        </div>

                                    )}

                                </div>
                            </div>
                            {this.props.admin &&
                                <div className="gantt-modal-footer position-relative">
                                    <div className={`modal-feedback-message d-flex align-items-center justify-content-between ${this.state.feedbackMessage && this.state.feedbackMessage.message ? `show message-${this.state.feedbackMessage.color}` : 'show'} `}>
                                        <span>{this.state.feedbackMessage && this.state.feedbackMessage.message}</span>
                                        {this.state.feedbackMessage && this.state.feedbackMessage.message &&
                                            <span className="message-close cursor-pointer" onClick={() => this.setState({ feedbackMessage: {} })}><X size={13} /></span>
                                        }
                                    </div>
                                    <Input type="text" bsSize="sm"
                                        value={this.state.justifications[`time_${item.code}`] && this.state.justifications[`time_${item.code}`].trim().length > 0 ? this.state.justifications[`time_${item.code}`] : ""}
                                        placeholder="Digite uma mensagem para contestar ou reprovar"
                                        //onChange={e => this.setState({ justification: e.target.value })}
                                        disabled={item.status === 'C'}
                                        onChange={(e) => this.setTimeJustification(e, item.code)}
                                    /*onKeyDown={ e => {
                                        if (e.key === 'Enter' && this.state.justification.trim().length > 0 ) {
                                            this.sendTimeContestation(item)
                                        }
                                    }}*/
                                    />
                                    {/*item.type === 'A' && item.status === 'A' &&
                            <div className="d-flex position-relative" style={{zIndex: 9999999999}}>
                                <InputMask 
                                    type='text'
                                    id="tooltip_1"
                                    className="form-control form-control-sm ml-50"
                                    style={{width: 50}}
                                    mask="99:99"
                                    value={this.state.splitTimes[`time_${item.code}`] && this.state.splitTimes[`time_${item.code}`].trim().length > 0 ? this.state.splitTimes[`time_${item.code}`]: ""}
                                    //onFocus={e => e.target.select()}
                                    onChange={(e) => this.setTimeSplit(e,item.code)}
                                    />
                            </div>
                            */}

                                    <ul className="action-buttons">
                                        {/*<li className="warning cursor-pointer" onClick={() => this.handleContestTime(modalInfo)}>Contestar</li>*/}
                                        {/*<li onClick={() => this.handleSendMessage(modalInfo)} className="info cursor-pointer">Chat</li>
                            <li className="success cursor-pointer" onClick={() => this.sendTimeApproval(modalInfo)}>Aprovar</li>*/}
                                        <React.Fragment>
                                            <li className={`warning cursor-pointer ${item.status === 'C' || this.state.justifications[`time_${item.code}`] && this.state.justifications[`time_${item.code}`].trim().length === 0 ? 'disabled' : ''}`}
                                                onClick={() => item.status !== 'C' && this.sendTimeContestation(item)}>Contestar</li>
                                            <li
                                                className={`danger cursor-pointer ${item.status === 'C' || this.state.justifications[`time_${item.code}`] && this.state.justifications[`time_${item.code}`].trim().length === 0 ? 'disabled' : ''}`}
                                                //onClick={() => item.status !== 'C' &&  this.sendTimeReprove(item)}
                                                onClick={() => this.selectTimeReprove(item)}
                                            >Reprovar</li>
                                        </React.Fragment>
                                    </ul>
                                </div>
                            }
                        </React.Fragment>
                        {/*Modal de confirmação de reprovação*/}
                        <div className={`split-time-confirmation ${confirmContestation === item.code ? 'show' : ''}`}>
                            <div
                                className={`split-time-confirmation-overlay ${confirmContestation === item.code ? 'show' : ''}`}
                                onClick={() => this.setState({ confirmContestation: false })}>

                            </div>
                            <div className={`split-time-confirmation-body ${confirmContestation === item.code ? 'show' : ''}`}>

                                <div className="gantt-modal-footer position-relative">
                                    <ul className="action-buttons flex-grow- text-left">
                                        <li className="default cursor-pointer" onClick={() => this.setState({ confirmContestation: null, feedbackMessage: {} })}>Voltar</li>
                                    </ul>
                                    <h6 className="flex-grow-1">Hora início da reprovação:</h6>
                                    <div className="d-flex">
                                        <InputMask
                                            type='text'
                                            id="tooltip_1"
                                            className="form-control form-control-sm ml-50 text-center"
                                            style={{ width: 50 }}
                                            mask="99:99"
                                            value={this.state.splitTimes[`time_${item.code}`] && this.state.splitTimes[`time_${item.code}`].trim().length > 0 ? this.state.splitTimes[`time_${item.code}`] : ""}
                                            //onFocus={e => e.target.select()}
                                            onChange={(e) => this.setTimeSplit(e, item.code)}
                                        />
                                        <ul className="action-buttons">
                                            <li className="danger cursor-pointer" onClick={() => {
                                                this.sendTimeReprove(item)
                                            }
                                            }>Confirmar</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Final da modal de confirmação de reprovação*/}

                        {/*Detalhes dos cliques nas opções de justificativa de inatividade*/}
                        <TaskInactivityJustification
                            type="time"
                            item={this.state.modals.taskInactivityJustifications}
                            toggleShow={this.handleCloseTaskInactivity} />
                    </React.Fragment>
                    {/*Envio de mensagem para o usuário*/}
                    <div className={`gantt-modal-form ${this.state.sendMessage ? 'show' : ''} d-flex flex-column`}>
                        <div className="gantt-modal-form-header">
                            <h5>Enviar mensagem</h5>
                        </div>
                        <div className="gantt-modal-form-body flex-grow-1">
                            <Input type="textarea" rows='9'
                                style={{ height: item.status === "A" ? 47 : 225, resize: 'none' }}
                                value={this.state.message}
                                onChange={e => this.setState({ message: e.target.value })} />
                        </div>
                        <div className="gantt-modal-footer">
                            <div>{feedbackMessage && <span className={feedbackMessage.color}>{feedbackMessage.message}</span>}</div>
                            <ul className="action-buttons">
                                <li className="default cursor-pointer" onClick={() => this.setState({ sendMessage: null })}>Fechar</li>
                                <li className="success cursor-pointer" onClick={() => this.sendTaskMessage(item)}>Enviar</li>
                            </ul>
                        </div>
                    </div>
                    {/*Envio de mensagem para o usuário*/}
                </div>
                {this.state.width < 728 && <div className="modal-close-button" onClick={this.onMouseLeave}><X size={15} /></div>}
            </div>
        )
    }



    render() {
        const { timeline, date, colors, workTime, totalTime, tracking, highlightTime } = this.props
        const { clientX, clientY, modalInfo, sendMessage, contestTime, feedbackMessage } = this.state
        const data = this.props.tasks.map((m, i) => {
            return {
                cardKey: m.Tarefa?.card?.cardKey ?? null,
                title: m.NomeTarefa,
                codigo: m.Codigo,
                line: i,
                items: m.Tempos && m.Tempos.sort((a, b) => moment(a.DataInicio) - moment(b.DataInicio)).map((t, e) => {
                    return {
                        code: t.Codigo,
                        title: m.NomeProjeto,
                        task: m.NomeTarefa,
                        description: m.Descricao,
                        start: t.DataInicio,
                        end: t.DataFim,
                        status: t.Status,
                        type: t.Tipo,
                        split: typeof t.split !== 'undefined' ? t.split : typeof t.Split !== 'undefined' ? t.Split : false,
                        justifications: t.Justificativas,
                        peopleCode: t.CodigoPessoa,
                        taskCode: t.CodigoAtividade,
                        position: e
                    }
                })
            }
        }).flat()
        const ascDate = data && data.map(m => m.items.map(t => {
            return {
                start: t.start,
                end: t.end ? t.end : moment.utc(-3).format('YYYY-MM-DD HH:mm:ss'),
            }
        })).flat()
        const dayFirstTime = ascDate && ascDate.sort((a, b) => moment(a.start) - moment(b.start)) && ascDate.sort((a, b) => moment(a.start) - moment(b.start))[0] && ascDate.sort((a, b) => moment(a.start) - moment(b.start))[0].start
        const dayLastTime = ascDate && ascDate.sort((a, b) => moment(a.end) - moment(b.end)) && ascDate.sort((a, b) => moment(a.end) - moment(b.end)).slice(-1) && ascDate.sort((a, b) => moment(a.end) - moment(b.end)).slice(-1)[0] && ascDate.sort((a, b) => moment(a.end) - moment(b.end)).slice(-1)[0].end
        const startDay = Number(moment(dayFirstTime).format('HH'))
        const endDay = Number(moment(dayLastTime).format('HH'))
        const time = endDay - startDay + 1 > 0 ? Array.from(Array(endDay - startDay + 1).keys()).map(m => m + startDay) : []
        //const time = []
        const dayTime = (((endDay - startDay) + 1) * 60)

        const dayTrack = tracking && tracking.tracking && tracking.tracking.map((m) => {
            return {
                diff: moment(m.endDate).diff(moment(m.startDate), 'minutes'),
                trackInit: m.startDate,
                trackEnd: m.endDate
            }
        })

        const dayIdleTime = dayTrack && dayTrack.reduce((accum, item) => accum + (item && item.diff > 0 ? item.diff : 0), 0)
        //INATIVIDADES DO DIA
        const dt = data && data.map((m, i) => {
            return m.items.map((item, o) => {
                const track = tracking && tracking.tracking && tracking.tracking.filter(f =>
                    moment(f.startDate).diff(moment(item.start), 'minutes') > 0 &&
                    moment(f.endDate).diff(moment(item.end), 'minutes') < 0 &&
                    moment(f.endDate).diff(moment(f.startDate), 'seconds') > 0
                ).map((m) => {
                    return {
                        id: m.id,
                        diff: moment(m.endDate).diff(moment(m.startDate), 'minutes'),
                        trackInit: m.startDate,
                        trackEnd: m.endDate,
                        timeInit: item.start,
                        timeEnd: item.end
                    }
                }).filter(f => f.diff > 0).flat()
                return track && track.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.trackInit === value.trackInit && t.trackEnd === value.trackEnd
                    )))
            })
        }).map(m => { return m.flat() }).flat()
            .reduce((accum, item) => accum + (item && item.diff > 0 ? item.diff : 0), 0)
        //INATIVIDADES DO DIA
        const fakeDaytime = dt > 0 ? dt : 0


        return (
            <div className="gantt" onMouseLeave={() => this.setState({ modalFixed: false, modalInfo: null, sendMessage: null, Justificativa: '' })}>
                <div className={`gantt-header d-flex justify-content-between ${this.props.borderTop ? 'br-t' : ''}`}>
                    <div className="">
                        <div style={{ fontSize: 17 }}><b>Atividade por: {secondsToTime(totalTime)}</b></div>

                        <b className="info">{weekDays[moment(date).weekday()]}, {moment(date).format('DD/MM')}</b> &mdash; <span>{workTime}</span>
                    </div>
                    <div>

                        {/*<span
                                    className={`modal-chart-occuped ${
                                    //colors[o] === 'red' ? 'danger' : 'success'
                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && !item.justifications[item.justifications.length - 1].ManagerJustification ? 'danger' : 
                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && item.justifications[item.justifications.length - 1].ManagerJustification ? 'pending' : 
                                    item.type === 'E' && item.status === 'A' ? 'approved' :  
                                    item.type === 'E' && item.status === 'R' ? 'reproved' :  
                                    item.type === 'A' && item.status === 'A' ? 'success' : 
                                    'success'
                                    }`}
                                ></span>*/}
                        <div className="gantt-idle flex-grow-1" onClick={() => this.taskInactivityJustificationDay(moment(date).format('YYYYMMDD'))}>
                            <div>Produtividade e ociosidade:</div>
                            <div className="d-flex">
                                <span className="active">
                                    <div className="percentual">{totalTime > 0 && fakeDaytime > 0 ? (100 - (fakeDaytime / totalTime) * 100).toFixed(2) : "100"}% ativo</div>
                                    <div className="time">{secondsToTime(totalTime - fakeDaytime)} ativo</div>
                                </span>
                                <div className="idle-scale">
                                    <div className="scale-active-time" style={{ width: `${(100 - (fakeDaytime / totalTime) * 100).toFixed(2)}%` }}>

                                    </div>
                                    <div className="scale-inactive-time" style={{ width: `${((fakeDaytime / totalTime) * 100).toFixed(2)}%` }}>

                                    </div>
                                </div>
                                <span className="inactive">
                                    <div className="percentual">{totalTime > 0 && fakeDaytime > 0 ? ((fakeDaytime / totalTime) * 100).toFixed(2) : "0"}%  ocioso</div>
                                    <div className="time">{fakeDaytime && secondsToTime(fakeDaytime)}  ocioso</div>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="gantt-body" >
                    <div className="gantt-time-details" >
                        {modalInfo &&
                            this.modalDetails(modalInfo.item, modalInfo.idleTime, modalInfo.difTime, modalInfo.totalTime, modalInfo.feedbackMessage)
                        }
                    </div>
                    <div className="gantt-chart" ref={this.myRef} onScroll={this.onScroll}>
                        <PerfectScrollbar
                            options={{ suppressScrollY: true }}
                            onScrollY={(container) => { }}
                            className="gantt-chart-body"
                            ref={this.origin}>
                            <div style={{ minWidth: '500px' }}>
                                {data && data.map((m, i) => {
                                    return (
                                        <div className="gantt-chart-line d-flex" key={`line_${i}`}>
                                            <div className={`${i % 2 === 0 ? 'odd' : 'even'} gantt-description truncate`}>
                                                {m.cardKey && <div><a href={`/card/${m.cardKey}`}>{m.cardKey}</a> - {m.title}</div>}
                                                {!m.cardKey && <div>{m.title}</div>}
                                            </div>
                                            <div className={`${i % 2 === 0 ? 'odd' : 'even'} gantt-full-description truncate`}>
                                                <h3>{m && m.items && m.items[0] && m.items[0].title}</h3>
                                                <p>{m && m.items && m.items[0] && m.items[0].task}</p>
                                            </div>

                                            <div className="flex-grow-1 d-flex gantt-day">

                                                {m.items.map((item, o) => {
                                                    const difTime = moment.parseZone(item.end).diff(moment.parseZone(item.start), 'minutes')
                                                    const difDayTimeStart = moment.parseZone(item.start).diff(moment(date), 'minutes')
                                                    const difDayTimeEnd = moment.parseZone(item.end).diff(moment(date), 'minutes')
                                                    const track = tracking && tracking.tracking && tracking.tracking.filter(f =>
                                                        moment(f.startDate).diff(moment(item.start), 'minutes') >= 0 &&
                                                        moment(f.endDate).diff(moment(item.end), 'minutes') <= 0 &&
                                                        moment(f.endDate).diff(moment(f.startDate), 'seconds') >= 0
                                                    ).map((m) => {
                                                        return {
                                                            diff: moment(m.endDate).diff(moment(m.startDate), 'minutes'),
                                                            trackInit: m.startDate,
                                                            trackEnd: m.endDate,
                                                            timeInit: item.start,
                                                            timeEnd: item.end
                                                        }
                                                    }).filter((value, index, self) =>
                                                        index === self.findIndex((t) => (
                                                            t.trackInit === value.trackInit && t.trackEnd === value.trackEnd
                                                        ))
                                                    )
                                                    const idleTime = track && track.reduce((accum, item) => accum + (item && item.diff > 0 ? item.diff : 0), 0)
                                                    return (
                                                        <React.Fragment key={`time_${item.code}`}>
                                                            <div
                                                                //onMouseEnter={(e) => this.onMouseEnter(e,item, i, m)} 
                                                                onMouseEnter={(e) => this.onMouseEnter(e, item, i, m, idleTime, difTime, totalTime, feedbackMessage)}
                                                                //onClick={this.makeModalFixed} this.state.modalFixed
                                                                // onMouseLeave={this.onMouseLeave}
                                                                onClick={() => this.handleClickItem(item, idleTime, difTime, totalTime)}
                                                                id={item.code}
                                                                className={`gantt-bar ${item.type === 'E' && item.status === 'P' && (item.justifications && item.justifications.filter(f => f.ManagerJustification === null) && item.justifications.filter(f => f.ManagerJustification === null).length === item.justifications.length) ? 'danger' :
                                                                    item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && !item.justifications[item.justifications.length - 1].ManagerJustification ? `pending dark ${item.split ? 'splited' : ''}` :
                                                                        item.type === 'E' && item.status === 'P' && item.justifications && item.justifications[item.justifications.length - 1] && item.justifications[item.justifications.length - 1].ManagerJustification ? `pending light ${item.split ? 'splited' : ''}` :
                                                                            item.type === 'E' && item.status === 'A' && item.split ? 'approved splited' :
                                                                                item.type === 'E' && item.status === 'A' ? 'approved' :
                                                                                    //item.type === 'E' && item.status === 'R' && item.split ? 'reproved splited' :  
                                                                                    (item.type === 'E' && item.status === 'R') || item.status === 'C' ? 'reproved' :
                                                                                        item.type === 'A' && item.status === 'A' ? 'success' :
                                                                                            item.type === 'A' && item.status === 'P' ? 'pending' :
                                                                                                item.type === 'A' && item.status === 'R' ? 'reproved splited' :
                                                                                                    'none'} ${item.code === highlightTime ? 'selected' : ''}`}
                                                                style={{
                                                                    //width: `${(difTime*100)/dayTime === 0 ? '2px' : `${(difTime*100)/dayTime}%`}`, 
                                                                    width: ((difDayTimeEnd - difDayTimeStart) * 100) / dayTime >= 0.4 ? `calc(${((difDayTimeEnd - difDayTimeStart) * 100) / dayTime}% - 1px)` : `2px`,
                                                                    left: `calc(${((difDayTimeStart - (startDay * 60)) * 100) / dayTime}%`
                                                                }}>
                                                                { }
                                                                {/*JSON.stringify(item.justifications && item.justifications[0] && item.justifications[0].ManagerJustification)*/}
                                                                {/*TRACKING CANVAS*/}
                                                                {track ? track.map((m, t) => {
                                                                    const difTrackTime = moment(m.trackEnd).diff(moment(m.trackInit), 'minutes')
                                                                    const difTrackDayTime = moment(m.trackInit).diff(moment(date), 'minutes')
                                                                    const difTrackPosition = moment(m.trackInit).diff(moment(item.start), 'minutes')
                                                                    const left = ((difTrackDayTime - (startDay * 60)) * 100) / dayTime - ((difDayTimeStart - (startDay * 60)) * 100) / dayTime
                                                                    return (
                                                                        <div
                                                                            key={`track_${o}_${i}_${t}`}
                                                                            className="gantt-tracking"
                                                                            style={{
                                                                                width: `${`${(difTrackTime * 100) / difTime}%`}`,
                                                                                left: `calc(${(difTrackPosition * 100) / difTime}%`
                                                                            }}
                                                                        >
                                                                            <span className="d-none">{JSON.stringify(m)}</span>
                                                                            <span className="d-none">{item.trackEnd}</span>
                                                                            <span className="d-none">{difTrackPosition}</span>
                                                                            <span className="d-none">{difTime}</span>
                                                                            <span className="d-none">{m.diff}</span>
                                                                            <span className="d-none">LEFT: {(difTrackPosition * 100) / difTime}</span>
                                                                            <span className="d-none">LEFT: {m.trackInit}</span>
                                                                            <span className="d-none">{(difTrackTime * 100) / difTime}%</span>
                                                                        </div>
                                                                    )

                                                                }) :
                                                                    <React.Fragment>
                                                                        <span className="d-none">{JSON.stringify(item)}</span>
                                                                        <span className="d-none">{item.status}</span>
                                                                        <span className="d-none">{`calc(${((difDayTimeEnd - difDayTimeStart) * 100) / dayTime}% - 1px)`}</span>
                                                                        <span className="d-none">{item.start}</span>
                                                                        <span className="d-none">{item.end}</span>
                                                                        <span className="d-none">{JSON.stringify(ascDate)}</span>
                                                                        <span className="d-none">dayTime:{dayTime}</span>
                                                                        <span className="d-none">dayFirstTime:{dayFirstTime}</span>
                                                                        <span className="d-none">dayLastTime:{dayLastTime}</span>
                                                                        <span className="d-none">startDay:{startDay}</span>
                                                                        <span className="d-none">endDay:{endDay}</span>
                                                                        <span className="d-none">time:{time}</span>
                                                                        <span className="d-none">{difTime}</span>
                                                                        <span className="d-none">{difDayTimeEnd}</span>
                                                                        <span className="d-none">{difDayTimeStart}</span>
                                                                        <span className="d-none">{date}</span>

                                                                    </React.Fragment>
                                                                }
                                                                {/*TRACKING CANVAS*/}
                                                                {/*Detalhes da edição*/}


                                                                {/*Final dos detalhes da edição*/}
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}

                                                {time && time.map(m =>
                                                    <div style={{ width: `${100 / (endDay - startDay + 1)}%` }} className={`${i % 2 === 0 ? 'odd' : 'even'} flex-grow-1 gantt-day-cells`} key={`day_time_${m}`}><span className="gantt-time">{m + 1}:00</span></div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="gantt-chart-footer d-flex">
                                    <div className={`gantt-description truncate`}></div>

                                    <div className="flex-grow-1 d-flex gantt-day">
                                        {time && time.map(m =>
                                            <div style={{ width: `${100 / (endDay - startDay + 1)}%` }} className={`footer-times flex-grow-1`} key={`day_time_f${m}`}><span className="mb-font">{m > 0 && m % 2 === 0 ? `${m}:00` : ``}</span></div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </PerfectScrollbar>

                    </div>
                </div>
                <Modal
                    isOpen={this.state.modals.taskInactivityJustificationsDay}
                    toggle={this.taskInactivityJustificationDay}
                    zIndex={9999999}
                    className="modal-dialog-centered">
                    <ModalHeader
                        toggle={this.taskInactivityJustificationDay}>
                        Inatividades do colaborador em {moment(this.state.modals.taskInactivityJustificationsDay).format('DD/MM/YYYY')}
                    </ModalHeader>
                    <ModalBody>
                        <TaskInactivityJustification
                            user={this.props.user ? this.props.user : this.props.codigo}
                            type="day"
                            item={this.state.modals.taskInactivityJustificationsDay}
                            toggleShow={() => null} />
                    </ModalBody>
                    <ModalFooter>
                        <Button.Ripple color="flat-danger" onClick={this.taskInactivityJustificationDay}>
                            <X size={18} /> Fechar
                        </Button.Ripple>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    sendMessage,
}, dispatch)
export default connect(null, mapDispatchToProps)(Gantt)