import React from 'react'
import Modal, { ModalBody, ModalTransition, ModalHeader, ModalTitle, ModalFooter } from '@atlaskit/modal-dialog';

function ModalSuricatoo(props) {
    return (
        <ModalTransition>
            {props.open &&
                <Modal testId={props.testId} width={props.width} height={props.height} onClose={props.onClose} shouldScrollInViewport={true} autoFocus={props.autoFocus ?? true}>
                    {props.header &&
                        <ModalHeader>
                            <ModalTitle appearance={props.appearance ?? ''}>
                                {props.header}
                            </ModalTitle>
                        </ModalHeader>}
                    <ModalBody>
                        {props.children}
                    </ModalBody>
                    {props.footer &&
                        <ModalFooter>
                            {props.footer}
                        </ModalFooter>}
                </Modal>}
        </ModalTransition>
    )
}
export default React.memo(ModalSuricatoo)