import themeConfig from "../../../configs/themeConfig"
import { Cache } from "../../../views/pages/cache/cache"

const theme = Cache.theme().get() ?? 'light'

const defaultTheme = { ...themeConfig, theme }

const customizerReducer = (state = defaultTheme, action) => {
  switch (action.type) {
    case "CHANGE_MODE":
      Cache.theme().set(action.mode)
      window.location.reload()
      return { ...state }
    case "COLLAPSE_SIDEBAR":
      return { ...state, sidebarCollapsed: true }
    case "CHANGE_NAVBAR_COLOR":
      return { ...state, navbarColor: action.color }
    case "CHANGE_NAVBAR_TYPE":
      return { ...state, navbarType: action.style }
    case "CHANGE_FOOTER_TYPE":
      return { ...state, footerType: action.style }
    case "CHANGE_MENU_COLOR":
      return { ...state, menuTheme: action.style }
    case "HIDE_SCROLL_TO_TOP":
      return { ...state, hideScrollToTop: action.value }
    default:
      return state
  }
}

export default customizerReducer
