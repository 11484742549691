import React, { Component } from 'react'
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    ButtonGroup,
    CardHeader,
    CardTitle,
    Alert,
    Progress,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    Spinner,
    Nav,
    NavItem,
    NavLink, UncontrolledPopover, PopoverHeader, PopoverBody, Popover
} from "reactstrap"
import * as Icon from "react-feather"
import Select from "react-select"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './time.css'
import Axios from 'axios'
import url from '../../../Config'
import { ToastError, ToastSuccess } from '../../../utility/ToastMessages'
import "../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { } from '@popperjs/core';
import PerfectScrollbar from "react-perfect-scrollbar"

import LoadingPost from '../../../utility/LoadingPost'
import Checkbox from "../../../components/@vuexy/checkbox/CheckboxesVuexy"
import InputSearch from '../component/InputSearch'

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"
import { Link } from 'react-router-dom'
import If from '../../../utility/If'
import { history } from '../../../history'

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import BaseComponentSuricatoo, { Translate } from '../../../utility/BaseComponentSuricatoo'
import { isDate } from 'moment'
import moment from 'moment-timezone'

import { isString } from 'formik'
import ModalEditarTarefa from './ModalEditarTarefa'
import TimerTarefa from './TimerTarefa'
import { TimerUtils } from '../../../utility/TimerConvertUtils'
import { carregarPessoas } from '../../../redux/actions/cache/pessoaActions'
import { trackingEventRemove } from '../../../redux/actions/tracking'
import AvatarPessoa from '../../../utility/AvatarPessoa'
import ModalObservacoes from '../../../utility/ModalObservacoes'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { secondsToTime } from '../../../utility/timeLegend'
import Badge from 'reactstrap/lib/Badge'
import Help from '../../../components/suricatoo/help/Help'
import Wizard from '../../../components/suricatoo/productWizard/Wizard'
import TourComponent from '../../../components/suricatoo/productTour/Tour'
import classnames from "classnames"
import PageVisibility from 'react-page-visibility';

import ExternalLink from '../../../assets/img/svg/external-link.svg'
import Azure from '../../../assets/img/svg/azure.svg'
import { ReactComponent as AppleLogo } from '../../../assets/img/svg/apple-logo.svg'
import { ReactComponent as WindowsLogo } from '../../../assets/img/svg/windows-logo.svg'
import Gantt from '../../../components/suricatoo/gantt/Gantt'
import Avatar from '../../../components/@vuexy/avatar/AvatarComponent'
import { logout } from "../../../redux/actions/auth/loginActions"
import TimerCount from './NewTimeCount'
import { timeround } from '../../../utility/timeround'
import { onlyUnique } from '../../../utility/onlyUnique'
import { errorHandle } from '../../../utility/error-handle'
import { paymentsConfigs } from '../../../utility/paymentsConfigs'
import { loginExpiration } from '../../../utility/loginExpiration'


const colors = ['primary', 'success', 'info', 'warning', 'danger', 'primary', 'success', 'info', 'warning', 'danger']

//America/Sao_Paulo
//America/Manaus

const helpTopics = {
    title: 'Tempos',
    caption: 'Gerencie atividades e tarefas',
    topics: [
        {
            id: 1,
            title: 'O que eu preciso para iniciar uma tarefa?',
            description: 'Inicialmente, você precisa cadastrar pelo menos um projeto e atribuir pelo menos uma atividade a ele. Depois, você precisará adicionar um usuário e vinculá-lo a um projeto.',
            keywords: "iniciar, tarefa, atividade"
        },
        {
            id: 2,
            title: 'Para que serve o ícone "Favoritos"?',
            description: 'Ao clicar no ícone "Favoritos" (estrela), você adiciona uma tarefa que faz com frequência à sidebar e, assim, sempre que precisar iniciar essa tarefa, basta clicar em "Favoritos" para que as informações sejam preenchidas automaticamente.',
            keywords: "favoritar"
        },
        {
            id: 3,
            title: 'E se eu precisar alterar um tempo?',
            description: 'Para isso, basta clicar no ícone de "Edição" para alterar adicionar um tempo que esquecei de iniciar, ajustar horários e alterar a descrição da tarefa',
            keywords: "alterar tarefa, mudar tarefa, editar tempo, mudar tempo, corrigir"
        }
    ]
}

const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
const weeks = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"]

const TarefaVazia = {
    Codigo: 0,
    Descricao: "",
    DataInclusao: new Date(),
    TempoTarefa: "00:00",
    TempoData: new Date(0, 0, 0, 0, 0, 0),
    Tempo: 0,
    OldTempo: 0,
    TempoAlterado: false,
    Status: 0,
    CodigoProjeto: 0,
    codigoTarefa: 0,
    codigoPessoa: 0,
    Justificativa: "",
    editarJustificativa: false,
    isOpenTour: false,
    doneTask: false
}

const calcDate = (dataFim) => {
    //verifica se a data final está dentro de uma semana que ainda não acabou
    const diff = moment(dataFim).diff(moment(), 'hours')

    //verifica o isoDay atual(hoje)
    const currentIsoDate = moment().isoWeekday()

    const calc = diff < 0 ? 5 :
        diff > 0 && currentIsoDate >= 5 ? 5 :
            currentIsoDate
    return calc
}

const weekRange = (position, data) => {
    const isoWeekDay = moment(data).isoWeekday()
    if (position === 'start') {
        //return moment(data).tz(moment.tz.guess()).startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss')
        return moment(data).add(-isoWeekDay + 1, 'days').format()
    } else {
        //return moment(data).tz(moment.tz.guess()).endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss')
        return moment(data).add(7 - isoWeekDay, 'days').format()
    }

}

let runningTime = 0

class Tempo extends BaseComponentSuricatoo {
    timer
    state = {
        searchTags: "",
        titleTodoTags: "",
        loading: true,
        date: moment().tz(moment.tz.guess()).startOf('day').format(),
        totalDay: 0,
        tasksLeft: [],
        tasks: [],
        ProjetosTarefas: [],
        Empresa: {
            Codigo: 0,
            Nome: '',
            JustificativaAprovacaoGestor: false,
            JustificativaObrigatoriaOperador: false
        },
        ProjetoTarefaSelecionado: {
            Codigo: 0,
            Nome: 'Nenhum projeto vinculado',
            NomeCliente: '...',
            Tarefas: []
        },
        TarefaSelecionada: {
            Codigo: 0,
            Nome: 'Nenhuma tarefa para esse projeto',
        },
        Tarefa: TarefaVazia,
        modal: false,
        modalObservacao: false,
        observacao: [],
        tempoSelecionado: null,
        Justificativa: "",
        ViewGestorPessoa: null,
        modalObservacaoTimeline: false,
        showHelp: false,
        showWizard: false,
        trackingEvent: null,
        taskRunning: null,
        taskRunningO: null,
        checkTasksRunning: true,
        activeTab: 1,
        running: false,
        timerOn: false,
        timerStart: 0,
        timerTime: 0,
        browserVisibility: true,
        isDisconnected: false,
        Projetos: [],
        Tarefas: [],
        trackings: [],
        intervalId: 0,
        requesting: false,
        width: null,
        height: null,
        tags: [],
        tagsSelected: [],
        todoSelectedTags: { Tags: '' },
        activityCodigoSelectedTags: 0
    }
    updateWindowDimensions = this.updateWindowDimensions.bind(this);

    startTimer = (initialTime) => {
        this.setState({
            timerOn: true,
            timerTime: this.state.timerTime,
            timerStart: initialTime ? moment(initialTime) : Date.now() - this.state.timerTime
        }, () => {
            clearInterval(this.timer)
            this.timer = setInterval(() => {
                const run = moment().diff(moment.parseZone(this.state.timerStart), 'seconds')
                const secToTime = secondsToTime(run)
                if (this.state.taskRunning > 0) {
                    if (secToTime && secToTime.split(':') && secToTime.split(':').length > 0 && parseInt(secToTime.split(':').pop()) === 30) {
                        Axios.post(`${url.RouterUrl}/api/clock/sync/${this.state.taskRunning}/clear`).then(res => {
                            this.novoAtualizaTempos(run)
                        })

                    }
                }
            }, 1000);

        });
    };

    resetTimer = () => {
        runningTime = 0
        this.setState({ timerOn: false });
        clearInterval(this.timer);
        this.setState({
            timerStart: 0,
            timerTime: 0
        });
    };


    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            requesting: false
        }))
    }
    toggleModalObservacoes = () => {
        this.setState(prevState => ({
            modalObservacaoTimeline: !prevState.modalObservacaoTimeline
        }))
    }

    toggleRunningModal = () => {
        this.setState(prevState => ({
            running: false
        }))
    }

    toggleTrackingModal = () => {
        this.setState(prevState => ({
            trackingModal: !prevState.trackingModal
        }))
    }

    toggleDoneTask = () => {
        this.setState(prevState => ({
            doneTask: !prevState.doneTask
        }))
    }

    toggleModalTags = () => {
        this.setState(prevState => ({
            modalTags: !prevState.modalTags
        }))
    }


    handleVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ browserVisibility: true }, () => {
                if (!this.state.isDisconnected) {
                    this.carregarTarefasUsuario(this.state.date)
                }
            })
        } else {
            this.setState({ browserVisibility: false })
        }
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            this.setState({ isDisconnected: false }, () => {
                this.carregarTarefasUsuario(this.state.date)
            })
        } else {
            this.setState({ isDisconnected: true })
        }
    }


    intervaloAtividade = () => {
        let taskRunning = this.state.tasks.find(f => f.tasks.find(f => f.Codigo === this.state.taskRunning))
        taskRunning = typeof taskRunning !== 'undefined' ? taskRunning.tasks.find(f => f.Codigo === this.state.taskRunning) : null
        if (taskRunning) {
            Axios.get(`${url.RouterUrl}/api/clock/sync/${taskRunning.Codigo}`).then(data => {

                if (data.data.atividade === taskRunning.Codigo) {
                    const { tempo, atividade, tempoUtilizado } = data.data
                    if (atividade === taskRunning.Codigo && taskRunning.Iniciada) {

                        this.setState({
                            tasks: this.state.tasks.map(d => {
                                const times = d && d.tasks.map(m => m.Tempos.filter(f => f.status !== 'R' /*&& f.status !== 'P'*/).map(t => {
                                    let tempo = t
                                    const startTime = moment(tempo.data_inicio).parseZone()
                                    const endTime = !tempo.data_fim ? moment(new Date()).tz(moment.tz.guess()) : moment(tempo.data_fim).parseZone()
                                    //return moment(endTime).diff(moment(startTime), 'minutes')
                                    if (!tempo.data_fim) {
                                        this.setState({
                                            timerStart: moment.parseZone(tempo.data_inicio)
                                        }, () => {
                                            //this.resetTimer()
                                            //removi this.startTimer()
                                        })
                                    }
                                    const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                                    return rounded.diff
                                    //return Math.round(moment(endTime).diff(startTime, 'seconds') / 60)
                                })).flat()
                                let day = d
                                day.tasks.map(m => {
                                    let task = m

                                    if (task.Codigo === atividade) {
                                        task.Tempo = tempo
                                    }
                                    return task

                                })
                                day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
                                return day
                            })
                        })

                    }

                    //Atualiza o tempo restante
                    if (taskRunning.Planejado && atividade === taskRunning.Codigo && taskRunning.Iniciada) {
                        this.atualizaTempoRestante(taskRunning.CodigoTarefa, data.data)
                    }
                }
            })
        } else {
            //this.resetTimer()
        }

    }

    componentDidMount = async () => {
        this.startTimer()
        this.handleConnectionChange();
        this.updateWindowDimensions();

        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener('resize', this.updateWindowDimensions);

    }
    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener('resize', this.updateWindowDimensions);

        clearInterval(this.timer);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const oldTracking = prevProps.trackingEvent && prevProps.trackingEvent.data && prevProps.trackingEvent.data.Codigo
        const newTracking = this.props.trackingEvent && this.props.trackingEvent.data && this.props.trackingEvent.data.Codigo
        if ((oldTracking !== newTracking) && newTracking > 0) {
            const { event, data } = this.props.trackingEvent
            let { Clientes, Nome } = data.Projeto
            const GMT = moment().tz(moment.tz.guess()).toString().split('GMT')[1]
            const horaMinuto = data.DataInclusao.replace('Z', '').split('T')
            const params = {
                Clientes: Clientes, //OK
                Codigo: data.Codigo,
                CodigoAtividadePai: data.CodigoAtividadePai,
                CodigoEmpresa: data.CodigoEmpresa,
                CodigoLabel: data.CodigoLabel,
                CodigoPessaoAlteracao: data.CodigoPessoaAlteracao,
                CodigoPessoa: data.personId, //vem fora do objeto principal mas OK
                CodigoProjeto: data.CodigoProjeto,
                CodigoTarefa: data.CodigoTarefa,
                DataAtualizacao: null, //NÃO VEM
                DataAtualizacaoTempoRestante: null, // NÃO VEM
                DataCriacao: data.DataCriacao,
                DataExclusao: null, // NÃO VEM
                DataInclusao: data.DataInclusao,
                DataInicio: null, // NÃO VEM
                Descricao: data.Descricao,
                Fixada: data.Fixada,
                Iniciada: data.Iniciada,
                Justificativa: "", //NÃO VEM
                LinkTarefa: data.LinkTarefa,
                NomePessoa: null, //NÃO VEM,
                NomeProjeto: Nome,
                NomeTarefa: data.Tarefa.Nome,
                Planejado: data.Tarefa.Planejado,
                QuantidadeTempoPendente: 0, //NÃO VEM
                Reprovados: [], //NÃO VEM
                StatusAtividade: data.Status,
                Tarefa: {
                    Arquivado: data.Tarefa.arquivado,
                    AtribuidoA: data.Tarefa.AtribuidoA,
                    Codigo: data.Tarefa.Codigo,
                    CodigoEmpresa: data.Tarefa.CodigoEmpresa,
                    CodigoIntegracao: data.Tarefa.CodigoIntegracao,
                    CodigoLabel: data.Tarefa.CodigoLabel,
                    CodigosProjeto: null, //NÃO VEM e provavelmente nao se usa
                    DataAtualizacao: null, //NÃO VEM e provavelmente nao se usa
                    DataCriacao: data.Tarefa.DataCriacao,
                    DataExclusao: null, //NÃO VEM  e provavelmente nao se usa
                    EmailAtribuidoA: data.Tarefa.EmailAtribuidoA,
                    LinkTarefaIntegracao: null, //NÃO VEM
                    Nome: data.Tarefa.Nome,
                    NomeAtribuidoA: data.Tarefa.NomeAtribuidoA,
                    //NomeProjeto: null, //NÃO VEM
                    Planejado: data.Tarefa.Planejado,
                    TarefaIntegracao: null, //NÃO VEM
                    TempoAdicionado: data.Tarefa.TempoAdicionado,
                    TempoPlanejado: data.Tarefa.TempoPlanejado,
                    TempoRestante: data.Tarefa.TempoRestante,
                    TempoUtilizado: data.Tarefa.TempoUtilizado,
                    Tipo: data.Tarefa.Tipo,
                    TipoIntegracao: data.Tarefa.TipoIntegracao,
                    TokenInstalacao: data.Tarefa.TokenInstalacao

                },
                TarefaArquivada: data.Tarefa.arquivado,
                Tempo: data.Tempo,
                TempoAdicionado: data.Tarefa.TempoAdicionado,
                TempoPlanejado: data.Tarefa.TempoPlanejado,
                TempoRestante: data.Tarefa.TempoRestante,
                TempoUtilizado: data.Tarefa.TempoUtilizado,
                Tempos: data.Tempos.map(j => {
                    let Justificativas = j
                    if (!j.Justificativas) {
                        Justificativas.Justificativas = []
                    }
                    return Justificativas
                }),
                Timezone: data.Timezone,
                Visivel: data.Visivel,
                running: null, //NÃO VEM
                start: null, //NÃO VEM
                time: moment(`${horaMinuto[0]}T00:00:00${GMT.substr(0, 3)}:${GMT.substr(GMT.length - 2)}`).format() //NÃO VEM
            }


            if (event === 'EVENT_ACTIVITY_UPDATE') {
                this.eventUpdate(params)
            } else if (event === 'EVENT_ACTIVITY_START') {
                //VERIFICA SE A ATIVIDADE JÁ ESTÁ EM EXECUÇÃO
                setTimeout(() => {
                    const targetDay = this.state.tasks.find(f => f.tasks.find(t => t.Codigo === data.Codigo))
                    const targetTask = targetDay && targetDay.tasks && targetDay.tasks.find(t => t.Codigo === data.Codigo)
                    if (typeof targetTask !== 'undefined') {
                        this.eventUpdate(params)
                    } else {
                        //this.eventStopTasks()
                    }

                }, 750)

            } else if (event === 'EVENT_ACTIVITY_STOP') {
                this.pausaTarefas(params)
            } else if (event === 'EVENT_ACTIVITY_INSERT') {
                setTimeout(() => {
                    this.eventInsert(params)
                }, 500)
            }
            this.props.trackingEventRemove()
        }
        //this.updateOnPageLoad()
        //VERIFICA A ATIVIDADE QUE ESTÁ RODANDO NO MOMENTO
        const prevTaskState = prevState.taskRunning
        const currTaskState = this.state.taskRunning
        //VERIFICA A ALTERAÇÃO NO ESTADO DA TAREFA PARA ATUALIZAR O CONTADOR DE MINUTOS
        if (JSON.stringify(prevTaskState) !== JSON.stringify(currTaskState) && this.state.taskRunning > 0) {
            const times = this.state.tasks.find(f => f.tasks.find(t => t.Codigo === this.state.taskRunning)) && this.state.tasks.find(f => f.tasks.find(t => t.Codigo === this.state.taskRunning)) && this.state.tasks.find(f => f.tasks.find(t => t.Codigo === this.state.taskRunning)) && this.state.tasks.find(f => f.tasks.find(t => t.Codigo === this.state.taskRunning)).tasks.find(t => t.Iniciada)
            const data_inicio = typeof times !== 'undefined' ? times.Tempos.find(j => !j.data_fim).data_inicio : null
            //this.resetTimer()
            this.setState({ timerStart: data_inicio ? data_inicio : new Date() })
            //this.startTimer(data_inicio)
        } else if (!this.state.taskRunning) {
            //this.resetTimer()
        }

    }

    updateOnPageLoad = () => {

        if (/*this.state.checkTasksRunning &&*/ this.state.tasks.length > 0) {
            const tasks = this.state.tasks.find(f => f.tasks.find(f => f.Iniciada))
            const taskRunning = typeof tasks !== 'undefined' ? tasks.tasks.find(f => f.Iniciada) : null
            this.setState({
                checkTasksRunning: false,
                taskRunning: taskRunning && taskRunning.Codigo > 0 ? taskRunning.Codigo : this.state.taskRunning,
                taskRunningO: taskRunning && taskRunning.CodigoTarefa > 0 ? taskRunning.CodigoTarefa : this.state.taskRunningO
            }, () => {
                let taskRunning = this.state.tasks.find(f => f.tasks.find(f => f.Codigo === this.state.taskRunning))
                taskRunning = typeof taskRunning !== 'undefined' ? taskRunning.tasks.find(f => f.Codigo === this.state.taskRunning) : null
                const { timerStart } = this.state;
                const addMinutes = moment().diff(moment(timerStart), 'seconds') / 60
                if (taskRunning) {
                    Axios.get(`${url.RouterUrl}/api/clock/sync/${taskRunning.Codigo}`).then(res => {
                        this.setState({
                            tasks: this.state.tasks.map(d => {
                                const times = d && d.tasks.map(m => m.Tempos.filter(f => f.status !== 'R' /*&& f.status !== 'P'*/).map(t => {
                                    let tempo = t
                                    const startTime = moment(tempo.data_inicio).parseZone()
                                    const endTime = !tempo.data_fim ? moment(new Date()).tz(moment.tz.guess()) : moment(tempo.data_fim).parseZone()
                                    const rounded = timeround(startTime.format('YYYY-MM-DDTHH:mm:ss'), endTime.format('YYYY-MM-DDTHH:mm:ss'))
                                    return rounded.diff

                                })).flat()
                                let day = d
                                day.tasks = d.tasks./*filter( f => f.Codigo !== this.state.Tarefa.Codigo).*/map(t => {
                                    let task = t
                                    if (task.CodigoTarefa === taskRunning.CodigoTarefa) {
                                        task.TempoUtilizado = res.data.tempoUtilizado + /*Math.round(addMinutes)*/ 0
                                        //task.TempoRestante = task.TempoPlanejado + task.TempoAdicionado - task.TempoUtilizado + Tempo
                                    }
                                    return task
                                })
                                day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
                                return day
                            })
                        })
                    })


                }

            })
        }
    }

    carregarTags() {
        Axios.get(`${url.RouterUrl}/api/tag`).then(({ data }) => {
            let tags = {}
            let tagsSelected = {}
            data.forEach(element => {
                tags[element.tag] = element.color
                if (!element.archived) {
                    tagsSelected[element.tag] = element.color
                }
            });
            this.setState({ tags, tagsSelected })
        })
    }

    eventInsert = (params) => {
        const { tasks } = this.state
        const taskAlreadyAdded = this.state.tasks.find(f => f.tasks.find(t => t.Codigo === params.Codigo))
        let newTasks = tasks.map(d => {
            const times = d && d.tasks.map(m => m.Tempos.filter(f => f.status !== 'R' /*&& f.status !== 'P'*/).map(t => {
                let tempo = t
                const startTime = moment(tempo.data_inicio).parseZone()
                const endTime = !tempo.data_fim ? moment(new Date()).tz(moment.tz.guess()) : moment(tempo.data_fim).parseZone()
                //return moment(endTime).diff(moment(startTime), 'minutes')
                if (!tempo.data_fim) {
                    this.setState({
                        timerStart: moment(tempo.data_inicio)
                    }, () => /*this.startTimer()*/ null)

                }
                const rounded = timeround(startTime.format('YYYY-MM-DDTHH:mm:ss'), endTime.format('YYYY-MM-DDTHH:mm:ss'))
                return rounded.diff
            })).flat()

            let day = d
            if (moment(day.data).format('YYYY-MM-DD') === moment(params.time).format('YYYY-MM-DD')) {
                //VERIFICA SE A TASK JÁ EXISTE

                day.tasks = typeof taskAlreadyAdded === 'undefined' ? [...day.tasks, params] : day.tasks
            } else {
                day.tasks = day.tasks.map(t => {
                    let task = t
                    task.Iniciada = false
                    return task
                })
            }
            day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
            return day
        })
        this.setState({ tasks: newTasks, taskRunning: params.Codigo, taskRunningO: params.CodigoAtividade })
    }

    eventUpdate = (data) => {

        const { tasks } = this.state
        let newTasks = tasks.map(d => {
            const times = d && d.tasks.map(m => m.Tempos.filter(f => f.status !== 'R' /*&& f.status !== 'P'*/).map(t => {
                let tempo = t
                const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                return rounded.diff
            })).flat()

            let day = d
            day.tasks.map(m => {
                let task = m
                if (task.Codigo === data.Codigo) {
                    let timeSum = data.Tempos.map(t => t.tempo)
                    timeSum = timeSum.reduce((accum, item) => accum + (item > 0 ? item : 0), 0)
                    task.Tempo = timeSum

                    task.Descricao = data.Descricao
                    task.CodigoProjeto = data.CodigoProjeto
                    task.CodigoTarefa = data.CodigoTarefa
                    task.NomeTarefa = data.NomeTarefa
                    task.NomeProjeto = data.NomeProjeto
                    //task.Clientes = atividade.Clientes // não vem
                    task.time = new Date(`${moment(data.DataInclusao).format('YYYY-MM-DD')} ${TimerUtils.HoraMinuto.MinutosToHoraMinuto(data.Tempo)}`)
                    task.Tempos = data.Tempos
                    task.Iniciada = data.Iniciada
                } else {
                    task.Tempos = task.Tempos
                    task.Iniciada = !data.Iniciada ? task.Iniciada : false
                }

                //ATUALIZA OS TERMPOS DAS TAREFAS PLANEJADAS
                if (task.CodigoTarefa === data.CodigoTarefa && task.Planejado) {
                    task.TempoUtilizado = data.Tarefa.TempoUtilizado
                }
                return task

            })
            day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
            return day
        })
        this.setState({ tasks: newTasks }, () => {
            const activeTask = this.state.tasks.find(f => f.tasks.find(t => t.Iniciada))
            if (typeof activeTask !== 'undefined') {
                const taskRunning = activeTask.tasks.find(t => t.Iniciada) && activeTask.tasks.find(t => t.Iniciada).Codigo ? activeTask.tasks.find(t => t.Iniciada) : null
                this.setState({ taskRunning: taskRunning.Codigo, taskRunningO: taskRunning.CodigoTarefa })
            }
        })
    }

    eventStopTasks = (data) => {
        const { tasks } = this.state
        let newTasks = tasks.map(d => {
            const times = d && d.tasks.map(m => m.Tempos.filter(f => f.status !== 'R' /*&& f.status !== 'P'*/).map(t => {
                let tempo = t
                const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                return rounded.diff
            })).flat()

            let day = d
            day.tasks.map(m => {
                let task = m
                task.Iniciada = false
                return task

            })
            day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
            return day
        })
        this.setState({ tasks: newTasks })
    }


    UNSAFE_componentWillMount() {
        if (!this.props.user) {
            switch (window.location.pathname) {
                case "/signup":
                    history.push(window.location.pathname + window.location.search)
                    return
                default:
                    history.push("/pages/login")
                    return
            }
        }

        this.carregarProjetosTarefaUsuario()
        //this.carregarTarefasUsuario(this.state.date)
        this.carregarTarefasFixadas()
        //this.carregarTarefasNaoFixadas()
        this.carregarEmpresa()
        this.props.carregarPessoas()
        Axios.get(`${url.RouterUrl}/api/back/pessoa/permissao`)
            .then(resp => {
                this.setState({ perfil: resp.data.data })
            })
        this.carregarTags()
    }
    pausaTarefas = (tarefa) => {
        if (typeof tarefa !== 'undefined') {
            this.updateTimesList('stop', tarefa)
            this.setState({ taskRunning: null, taskRunningO: null })
        }

        this.novoAtualizaTempos(tarefa, 0)
        runningTime = 0
        this.resetTimer()
        /*this.state.tasks.forEach(item => {
            item.tasks.forEach(t => {
                t.Iniciada = false
                clearInterval(t.running)
            })
        })
        this.setState({ tasks: [...this.state.tasks] })*/
    }

    startAtividade = (tarefa) => {
        if (typeof tarefa !== 'undefined') {
            this.updateTimesList('start', tarefa)
            this.setState({ taskRunning: tarefa.Codigo, taskRunningO: tarefa.CodigoTarefa })
        }
        /*this.state.tasks.forEach(item => {
            item.tasks.forEach(t => {
                if (task.Codigo === t.Codigo && task.DataInicio === t.DataInicio)
                    t.Iniciada = true
            })
        })
        this.setState({ tasks: [...this.state.tasks] })*/
    }


    updateTimesList = (action, tarefa) => {

        const atividade = {
            Codigo: tarefa.Codigo, // ok
            CodigoEmpresa: tarefa.CodigoEmpresa, //ok
            DataCriacao: tarefa.DataCriacao, //ok
            DataAtualizacao: tarefa.DataAtualizacao, //NÃO VEM
            DataExclusao: tarefa.DataExclusao, //NÃO VEM
            CodigoPessoa: tarefa.CodigoPessoa, //OK
            CodigoPessaoAlteracao: tarefa.CodigoPessaoAlteracao, //OK
            CodigoAtividadePai: tarefa.CodigoAtividadePai, //OK
            DataInclusao: tarefa.DataInclusao, //OK
            Fixada: tarefa.Fixada, //OK
            Visivel: tarefa.Visivel, // OK
            NomePessoa: tarefa.NomePessoa, //NÃO VEM
            Justificativa: tarefa.Justificativa, //NÃO VEM
            StatusAtividade: tarefa.StatusAtividade, //NÃO VEM
            QuantidadeTempoPendente: tarefa.QuantidadeTempoPendente, //NÃO VEM
            TarefaArquivada: tarefa.TarefaArquivada, //NÃO VEM
            DataInicio: tarefa.Projeto && tarefa.Projeto.DataInicio, //OK (ESTÁ DENTRO DE Projeto)
            CodigoProjeto: tarefa.CodigoProjeto, //OK
            CodigoTarefa: tarefa.CodigoTarefa, //OK
            Clientes: tarefa.Projeto && tarefa.Projeto.Clientes, //OK
            NomeProjeto: tarefa.Projeto && tarefa.Projeto.Nome, //OK
            NomeTarefa: tarefa.Tarefa.Nome, //OK
            Descricao: tarefa.Descricao, //OK
            Tempo: tarefa.Tempo, //OK, MAS ESTÁ VINDO COMO 0 EM UMA TAREFA EDITADA QUE TEM TEMPO, VER COM ALISSON
            Iniciada: tarefa.Iniciada, //OK
            start: tarefa.Iniciada, //OK
            Reprovados: null, //NÃO USA MAIS, POR ISSO COLOCADO COMO NULL
            Planejado: tarefa.Tarefa.Planejado, //OK
            TempoPlanejado: tarefa.Tarefa.TempoPlanejado, //OK
            TempoRestante: tarefa.Tarefa.TempoRestante, //OK
            TempoUtilizado: tarefa.Tarefa.TempoUtilizado, //OK
            TempoAdicionado: tarefa.Tarefa.TempoAdicionado, //OK
            DataAtualizacaoTempoRestante: tarefa.DataAtualizacaoTempoRestante, //OK -- NÃO VEM NO POST
            LinkTarefa: tarefa.LinkTarefa, //OK
            CodigoLabel: tarefa.CodigoLabel, //OK
            Tempos: tarefa.Tempos //OK
        }
        let tasks = this.state.tasks.map(d => {
            const times = tarefa && d.tasks.map(m => m.Tempos.filter(f => f.status !== 'R' /*&& f.status !== 'P'*/).map(t => {
                let tempo = t
                const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                return rounded.diff
            })).flat()

            let timerStart = tarefa.Tempos
            timerStart = timerStart.find(f => !f.data_fim)
            if (typeof timerStart !== 'undefined') {
                this.setState({
                    timerStart: moment.parseZone(timerStart.data_inicio).format()
                }, () => {

                })
            }

            let day = d
            day.tasks.map(m => {
                let task = m
                if (task.Codigo === tarefa.Codigo) {
                    let timeSum = tarefa.Tempos.map(t => t.tempo)
                    timeSum = timeSum.reduce((accum, item) => accum + (item > 0 ? item : 0), 0)
                    task.Tempo = timeSum
                    task.time = new Date(`${moment(m.DataCriacao).format('YYYY-MM-DD')} ${TimerUtils.HoraMinuto.MinutosToHoraMinuto(timeSum)}`)
                    //task.Iniciada = true
                    task.Iniciada = atividade.Iniciada
                    task.Tempos = tarefa.Tempos
                } else {
                    task.Iniciada = false
                    task.Tempos = task.Tempos.map(tp => {
                        let tempo = tp
                        if (!tempo.data_fim) {
                            tempo.data_fim = moment(new Date()).tz(moment.tz.guess()).format()
                        }
                        return tempo
                    })
                }
                return task
            })
            day.total = times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0)
            return day
        })

        //Axios.post(`${url.RouterUrl}/api/clock/sync/${atividade.Codigo}/clear`)
        /*const index = this.state.tasks.findIndex( f => moment(f.data).format('YYYY-MM-DD') === moment(tarefa.DataInicio).format('YYYY-MM-DD'))
        let tasks = [...this.state.tasks];
        let task = {...tasks[index]};
        task.tasks = task.tasks.map( m => {
            let timeSum = tarefa.Tempos.map( t => t.tempo)
            timeSum = timeSum.reduce((accum,item) => accum + (item > 0 ? item : 0), 0)
            let task = m
            task.Tempo = timeSum
            if(task.Codigo === tarefa.Codigo){
                task.time = new Date(`${moment(m.DataCriacao).format('YYYY-MM-DD')} ${TimerUtils.HoraMinuto.MinutosToHoraMinuto(timeSum)}`)
                //task.Iniciada = true
                task.Iniciada = atividade.Iniciada
                task.Tempos = tarefa.Tempos
            } else {
                task.Iniciada = false
                task.Tempos = task.Tempos
            }
            return task
        })
        tasks[index] = task;*/

        //this.atualizaTempoRestanteAtividades(atividade)
        //this.setState({ tasks, loading: false, date: tarefa.continueTask ? new Date() : this.state.date })
        //this.toggleModal()
        this.setState({ tasks }, () => {
            if (action === 'stop') {
                this.resetTimer()
            }
            this.setState({
                requesting: false
            })

        })

    }

    //Inclusão de um novo endpoint para obter o GMT onde o usuário fez login. Caso o usuário altere o GMT, o sistema impede o usuário de dar start em uma atividade que começou em outro GMT


    adicionarTempo = (tarefa, tempo) => {

        const newTasks = this.state.tasks.map(d => {
            const times = d && d.tasks.map(m => m.Tempos.filter(f => f.status !== 'R' /*&& f.status !== 'P'*/).map(t => {
                let tempo = t
                const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                return rounded.diff
            })).flat()

            let day = d
            day.tasks = d.tasks.map(t => {
                let task = t
                if (task.CodigoTarefa === tarefa.CodigoTarefa) {
                    task.TempoAdicionado = tempo
                }
                return task
            })
            day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
            return day
        })
        this.setState({ tasks: newTasks })
    }

    /*pararAtividade = (atividade) => {
        //this.resetTimer()
        this.acoesAtividadesMenuEsquerda(atividade, 'parar')
            .then(resp => {
                if (!resp.status === 200) ToastError(resp.response.data.message)
            })
            .catch(resp => { ToastError(resp.response.data.message) })
    }*/

    /*iniciarAtividade = (atividade) => {
        //this.resetTimer()
        //this.startTimer()
        //verifica se a tarefa está em execução no momento
        this.acoesAtividadesMenuEsquerda(atividade, 'iniciar')
            .then(resp => {
                if (!resp.status === 200) ToastError(resp.response.data.message)
            })
            .catch(resp => { ToastError(resp.response.data.message) })
    }*/

    /*fixarAtividade = (atividade) => {
        return this.acoesAtividadesMenuEsquerda(atividade, 'fixar')
    }

    desfixarAtividade = (atividade) => {
        return this.acoesAtividadesMenuEsquerda(atividade, 'desfixar')
    }
    atividadeNaoVisivel = (atividade) => {
        return this.acoesAtividadesMenuEsquerda(atividade, 'invisivel')
    }
    acoesAtividadesMenuEsquerda = (atividade, acao) => {
        return Axios.post(`${url.RouterUrl}/api/back/atividade/${atividade}/${acao}`)
    }*/
    toggleSetFavorite = (acao, codigo) => {
        Axios.post(`${url.RouterUrl}/api/back/atividade/${codigo}/${acao}`).then(resp => {

            if (resp.status === 200 && resp.data.status === "success") {
                let tarefa = this.state.tasks.find(day => day.tasks.find(task => task.Codigo === codigo)).tasks.find(task => task.Codigo === codigo)
                tarefa.fixo = !tarefa.fixo
                tarefa.data = tarefa.time
                tarefa.Fixada = !tarefa.Fixada
                this.setState({
                    tasksLeft: acao === 'fixar' ? [...this.state.tasksLeft, tarefa] : this.state.tasksLeft.filter(f => f.Codigo !== codigo)
                })
            } else {
                ToastError(resp.response.data.message)
            }


        })
            .catch(error => {
                const err = errorHandle(error)
                ToastError(error.message)
            })
    }
    carregarEmpresa() {
        Axios.get(`${url.RouterUrl}/api/back/empresa/resumido/${this.props.user.codigo_empresa}`)
            .then(resp => {
                if (resp.status === 200 && resp.data.status === "success") {
                    this.setState({
                        Empresa: resp.data.data,
                    })
                } else {
                    ToastError(resp.response.data.message)
                }
            })
            .catch(error => {
                const err = errorHandle(error)
                ToastError(error.message)
            })
    }

    carregarTarefasNaoFixadas() {
        let dataBase = new Date()
        dataBase.setDate(dataBase.getDate() - 1)
        const data = `${dataBase.getUTCFullYear()}-${dataBase.getMonth() + 1}-${dataBase.getDate()}`
        Axios.get(`${url.RouterUrl}/api/back/atividade/${data}/naofixadasporda`)
            .then(resp => {
                if (resp.status === 200 && resp.data.status === "success") {
                    resp.data.data = resp.data.data ?? []
                    const tarefas = resp.data.data.map((item) => {
                        return {
                            ...item,
                            data: new Date(item.DataInclusao),
                            fixo: false
                        }
                    })
                    this.setState({ tasksLeft: [...this.state.tasksLeft, ...tarefas] })
                } else {
                    ToastError(resp.response.data.message)
                }
            })
            .catch(error => {
                const err = errorHandle(error)
                ToastError(error.message)
            })
    }
    carregarTarefasFixadas() {
        Axios.get(`${url.RouterUrl}/api/back/atividade/fixadas`)
            .then(resp => {
                if (resp.status === 200 && resp.data.status === "success") {
                    resp.data.data = resp.data.data ?? []
                    const tarefas = resp.data.data.map((item) => {
                        return {
                            ...item,
                            data: new Date(item.DataInclusao),
                            DataInicio: moment.parseZone(item.DataInclusao).startOf('day').format(),
                            Tempos: item.Tempos ? item.Tempos : [],
                            fixo: true
                        }
                    })
                    this.setState({ tasksLeft: [...tarefas, ...this.state.tasksLeft] })
                } else {
                    ToastError(resp.response.data.message)
                }
            })
            .catch(resp => {
                ToastError(resp.response.data.message)
            })
    }
    carregarProjetosTarefaUsuario() {
        const { codigo } = this.props.user
        Axios.get(`${url.RouterUrl}/api/back/pessoa/${codigo}/carregarprojetos`)
            .then(resp => {
                if (resp.status === 200 && resp.data.status === "success") {
                    resp.data.data = resp.data.data ?? []
                    const projeto = resp.data.data.length > 0 ? resp.data.data[0] : {
                        Codigo: 0,
                        Nome: 'Nenhum projeto vinculado',
                        NomeCliente: '...',
                        Tarefas: []
                    }
                    projeto.Tarefas = projeto.Tarefas ?? []
                    const tarefa = projeto.Tarefas.length > 0 ? projeto.Tarefas[0] : {
                        Codigo: 0,
                        Nome: 'Nenhuma tarefa para esse projeto',
                    }
                    this.setState({
                        ProjetosTarefas: resp.data.data.map(m => {
                            let task = m
                            if (!task.Tarefas) {
                                task.Tarefas = []
                            }
                            return task
                        }),
                        ProjetoTarefaSelecionado: projeto,
                        TarefaSelecionada: tarefa
                    })
                } else {
                    ToastError(resp.response.data.message)
                }
            })
            .catch(error => {
                const err = errorHandle(error)
                ToastError(error.message)
            })
    }
    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    carregarTarefasUsuario(data, e) {
        //this.setState({ loading: true })

        if (this.state.ViewGestorPessoa && e === undefined) {
            e = this.state.ViewGestorPessoa
        }
        if (this.state.ViewGestorPessoa && e !== null) {
            if (this.state.ViewGestorPessoa.codigo !== e.codigo) {
                this.state.ViewGestorPessoa = e
            }
            e = this.state.ViewGestorPessoa
        }

        const dataInicio = moment(weekRange('start', data)).format('YYYY-MM-DD')
        const dataFim = moment(weekRange('end', data)).format('YYYY-MM-DD')

        Axios.get(`${url.RouterUrl}/api/back/atividade${e ? '/' + e.codigo : ''}/${dataInicio}/${dataFim}`)
            .then(resp => {
                if (resp.status === 200 && resp.data.status === "success") {
                    const days = this.getDatesWeek()
                    const tasks = days.map(m => {
                        const dayTasks = resp && resp.data && resp.data.data ? resp.data.data.filter(f => {
                            const utcOffset = moment.parseZone().utcOffset() / 60
                            const GMT = moment().tz(moment.tz.guess()).toString().split('GMT')[1]
                            const horaMinuto = f.DataInclusao.replace('Z', '').split('T')
                            return moment(`${horaMinuto[0]}T00:00:00${GMT.substr(0, 3)}:${GMT.substr(GMT.length - 2)}`).format() === moment(m).format()
                        }) : []
                        const times = dayTasks && dayTasks.filter(f => f.Tempos).map(t => t.Tempos.filter(s => s.status === "A" || s.status === "S" ||
                            (s.status === "P" && s.Justificativas.filter(j => j.Status !== "R")
                            ).length > 0).map(t => {
                                let tempo = t
                                const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                                return rounded.diff
                            })).flat()
                        return {
                            data: m,
                            tasks: dayTasks !== 'undefined' ? dayTasks.filter(f => f.Tempos).map(t => {
                                let task = t
                                task.start = false
                                task.time = new Date(moment(m).parseZone().startOf('day').add(task.Tempo, 'minutes'))
                                task.TempoRestante = task && task.Tarefa && task.Tarefa.TempoRestante
                                task.Tarefa.OpenPopover = false
                                return task
                            }) : [],
                            total: dayTasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
                        }
                    })

                    const codigosProjetos = tasks.map(m => m.tasks.map(p => p.CodigoProjeto)).flat().filter(onlyUnique)
                    const codigosTaredfas = tasks.map(m => m.tasks.map(p => p.CodigoTarefa)).flat().filter(onlyUnique)
                    this.setState({
                        tasks: [...tasks],
                        trackings: [],
                        Projetos: codigosProjetos.map(m => {
                            let projeto = resp && resp.data && resp.data.data.map(m => { return { Nome: m.NomeProjeto, Codigo: m.CodigoProjeto } }).find(f => f.Codigo === m)
                            let Total = tasks.map(t => t.tasks.filter(f => f.CodigoProjeto === m).map(o => o.Tempos).flat()).flat()
                            projeto.Total = Total.map(tot => timeround(tot.data_inicio, tot.data_fim).diff).reduce((accum, item) => accum + (item > 0 ? item : 0), 0)
                            return projeto
                        }),
                        Tarefas: codigosTaredfas.map(m => {
                            let tarefa = resp && resp.data && resp.data.data.map(m => m.Tarefa).find(f => f.Codigo === m)
                            let Total = tasks.map(t => t.tasks.filter(f => f.CodigoTarefa === m).map(o => o.Tempos).flat()).flat()
                            tarefa.Total = Total.map(tot => timeround(tot.data_inicio, tot.data_fim).diff).reduce((accum, item) => accum + (item > 0 ? item : 0), 0)
                            return tarefa
                        }),
                        //Capacidade: this.props.pessoas.find( f => f.Codigo === this.props.user.codigo) && this.props.pessoas.find( f => f.Codigo === this.props.user.codigo).CapacidadeSemana,
                        loading: false
                    }, () => {
                        this.updateOnPageLoad()
                        //this.carregarResumo(e ? e.codigo : this.props.user.codigo, dataInicio, dataFim)
                        this.state.tasks.forEach(item => {
                            this.carregaTrakingMouseTeclado(e ? e.codigo : this.props.user.codigo, moment(item.data).format('YYYY-MM-DD'))
                        })

                        const { Timezone } = this.props.detalhesUsuario
                        const currTimeZone = moment.tz.guess()

                        const runningTask = tasks.find(day => day.tasks.find(task => task.Iniciada))
                        if (typeof runningTask !== 'undefined' && Timezone !== currTimeZone) {
                            const getRunningTask = runningTask.tasks.find(task => task.Iniciada)
                            this.timer.pararAtividade(getRunningTask)
                            setTimeout(() => {
                                this.props.logout()
                            }, 200)
                        } else if (typeof runningTask === 'undefined' && Timezone !== currTimeZone) {
                            this.props.logout()
                        }


                    })
                } else {
                    ToastError(resp && resp.response && resp.response.data)
                }
            })
            .catch(resp => {
                //ToastError(resp.response.data.message)
            })
    }


    deletarAtividade = (time) => {
        this.setState({ loading: true })
        const { date } = this.state
        const dataDeletar = moment(date).format('YYYY-MM-DD')
        /*Axios.delete(`${url.RouterUrl}/api/back/atividade/${this.state.Tarefa.Codigo}/${dataDeletar}`)
            .then(resp => {
                this.setState({ taskRunning: null })
                if (!resp.status === 200) {
                    ToastError(resp.response.data)
                }
            })
            .catch(resp => {
                //ToastError(resp.response.data.message)
            })*/

        /*setTimeout(() => {
            const dt = moment(this.state.date).format('YYYY-MM-DD')
            const diaTarefa = this.state.tasks.find(x => moment(x.data).format('YYYY-MM-DD') === dt)
            if (diaTarefa) {
                const index = diaTarefa.tasks.map(x => x.Codigo).indexOf(this.state.Tarefa.Codigo)
                diaTarefa.tasks.splice(index, 1)
                this.subtraiTempoRestanteAtividades(this.state.Tarefa)
                this.setState({ tasks: [...this.state.tasks], loading: false, modal: false })
            }
            //this.toggleModal()
        }, 100);*/
        this.setState({ requesting: true })
        Axios.delete(`${url.RouterUrl}/api/activity/${this.state.Tarefa.Codigo}`)
            .then(resp => {
                if (!resp.status === 200) {
                    ToastError(resp.response.data)
                } else {

                    /*setTimeout(() => {
                        const dt = moment(this.state.date).format('YYYY-MM-DD')
                        const diaTarefa = this.state.tasks.find(x => moment(x.data).format('YYYY-MM-DD') === dt)
                        if (diaTarefa) {
                            const index = diaTarefa.tasks.map(x => x.Codigo).indexOf(this.state.Tarefa.Codigo)
                            diaTarefa.tasks.splice(index, 1)
                            this.subtraiTempoRestanteAtividades(this.state.Tarefa)
                            this.setState({ tasks: [...this.state.tasks], loading: false, modal: false, requesting: false })
                        }
                        //this.toggleModal()
                    }, 100);*/


                    const { CodigoTarefa, Tempo } = this.state.Tarefa
                    const newTasks = this.state.tasks.map(d => {

                        let day = d
                        day.tasks = d.tasks.filter(f => f.Codigo !== this.state.Tarefa.Codigo).map(t => {
                            let task = t
                            if (task.CodigoTarefa === CodigoTarefa) {
                                task.TempoUtilizado = task.TempoUtilizado - time
                                task.TempoRestante = task.TempoPlanejado + task.TempoAdicionado - task.TempoUtilizado + Tempo
                            }
                            return task
                        })
                        const times = day && day.tasks.map(t => t.Tempos.filter(s => s.status === "A" || s.status === "S" ||
                            (s.status === "P" && s.Justificativas.filter(j => /*preferencesConfig && preferencesConfig.DONT_PAY_CONTESTEDS ? j.Status !== "J" && j.Status !== "R" :*/ j.Status !== "R")
                            ).length > 0).map(t => {
                                let tempo = t
                                const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                                return rounded.diff
                            })).flat()


                        day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
                        return day
                    })


                    this.setState({ tasks: newTasks, loading: false, modal: false, requesting: false })
                    if (this.state.Tarefa.Planejado && this.state.Tarefa.TarefaArquivada) {
                        this.timer.arquivarDesarquivar(this.state.Tarefa, 'desarquivar')
                    }

                }
            })
            .catch(error => {
                this.setState({ loading: false })
                const err = errorHandle(error)
                ToastError(error.message)
            })
    }

    salvarAtividade = (Tarefa, projetoTarefaSelecionada, tarefaSelecionada, times, justificatory) => {
        if (this.state.Empresa.JustificativaAprovacaoGestor) {
            if (Tarefa.Tempo > Tarefa.OldTempo && (Tarefa.Justificativa.trim() === "" || !Tarefa.Justificativa)) {
                ToastError('Inclua uma justificativa de alteração do tempo')
                return
            }
        }
        const { codigo } = this.props.user
        Tarefa.codigoPessoa = codigo
        Tarefa.CodigoProjeto = projetoTarefaSelecionada
        Tarefa.codigoTarefa = tarefaSelecionada
        if (moment.parseZone(Tarefa.DataCriacao).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
            Tarefa = Tarefa.DataCriacao ? {
                Codigo: 0,
                CodigoProjeto: Tarefa.CodigoProjeto,
                DataInclusao: new Date(),
                Descricao: Tarefa.Descricao,
                Justificativa: "",
                OldTempo: 0,
                Status: 0,
                Tempo: 0,
                TempoAlterado: false,
                TempoData: new Date(0, 0, 0, 0, 0, 0),
                TempoDia: 0,
                TempoTarefa: "00:00",
                codigoPessoa: Tarefa.CodigoPessoa,
                CodigoTarefa: Tarefa.CodigoTarefa,
                doneTask: false,
                editarJustificativa: false,
                isOpenTour: false
            } : Tarefa
        } else if (moment(this.state.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') || Tarefa.continueTask) {
            Tarefa.DataInclusao = new Date()
        } else {
            Tarefa.DataInclusao = this.state.date
        }

        /**/

        Tarefa.DataInclusao = moment.parseZone(Tarefa.DataInclusao).format()

        this.setState({ loading: true })
        const action = Tarefa.Codigo === 0 ? Axios.post : Axios.put
        Tarefa.TempoDia = Tarefa.Tempo

        Tarefa.TempoAlterado = Tarefa.Codigo > 0 && Tarefa.OldTempo !== Tarefa.Tempo

        if (Tarefa.Tempo < 0) {
            ToastError('Tempo não pode ser negativo')
            return
        }

        if (Tarefa.continueTask) {
            if (Tarefa.CodigoAtividadePai === 0 || Tarefa.CodigoAtividadePai === null) {
                Tarefa.CodigoAtividadePai = Tarefa.OldCodigo
            }
            Tarefa.Codigo = Tarefa.OldCodigo
        }

        const params = {
            description: Tarefa.Descricao,
            inclusionDate: moment(Tarefa.DataInclusao).format('YYYY-MM-DD'),
            time: Tarefa.Tempo,
            justification: Tarefa.Justificativa ? Tarefa.Justificativa : justificatory ? justificatory : '',
            taskCode: tarefaSelecionada,
            projectCode: projetoTarefaSelecionada,
            tracking: times.filter(f => f.start && f.end).map(m => {
                const startTime = m.start.split(':')
                const endTime = m.end.split(':')
                if (m.id) {
                    return {
                        id: m.id,
                        startDate: `${startTime[0]}:${startTime[1]}`,
                        endDate: `${endTime[0]}:${endTime[1]}`,
                        changed: m.changed
                    }
                } else {
                    return {
                        startDate: `${startTime[0]}:${startTime[1]}`,
                        endDate: `${endTime[0]}:${endTime[1]}`
                    }
                }

            })

        }

        action(`${url.RouterUrl}/api/activity`, params)
            .then(resp => {
                if (resp.status === 200) {
                    const atividade = {
                        Clientes: Tarefa.Clientes,
                        Codigo: resp.data.Codigo, // ok
                        CodigoEmpresa: resp.data.CodigoEmpresa, // ok
                        DataCriacao: resp.data.DataCriacao,
                        DataAtualizacao: resp.data.DataAtualizacao, //NÃO TEM
                        DataExclusao: resp.data.DataExclusao, //NÃO VEM
                        CodigoPessoa: resp.data.CodigoPessoa,
                        CodigoPessaoAlteracao: resp.data.CodigoPessaoAlteracao, //NÂO TEM
                        CodigoAtividadePai: resp.data.CodigoAtividadePai,
                        DataInclusao: resp.data.DataInclusao,
                        Fixada: resp.data.Fixada,
                        Visivel: resp.data.Visivel,
                        NomePessoa: resp.data.NomePessoa, //NÃO TEM
                        Justificativa: resp.data.Justificativa, //NÃO TEM
                        StatusAtividade: resp.data.StatusAtividade, //NÃO VEM
                        QuantidadeTempoPendente: resp.data.QuantidadeTempoPendente, //NÃO TEM
                        TarefaArquivada: resp.data.TarefaArquivada, //NÃO TEM
                        DataInicio: resp.data.DataInclusao, //NAO VEM, POR ISSO USEI A DATA DA INCLUSAO
                        CodigoProjeto: resp.data.CodigoProjeto, //ok
                        CodigoTarefa: resp.data.CodigoTarefa, //ok
                        Clientes: resp.data.Projeto.Clientes, //OK
                        NomeProjeto: resp.data.Projeto.Nome, //ok
                        NomeTarefa: resp.data.Tarefa.Nome, //ok
                        Descricao: resp.data.Descricao, //ok
                        Tempo: resp.data.Tempo, //ok
                        Tempos: resp.data.Tempos, //ok
                        Iniciada: resp.data.Iniciada, //OK
                        start: resp.data.Iniciada, //OK
                        Timezone: resp.data.Timezone,
                        Reprovados: null, //NÃO USA MAIS, POR ISSO COLOCADO COMO NULL
                        Planejado: resp.data.Tarefa.Planejado, //ok
                        TempoPlanejado: resp.data.Tarefa.TempoPlanejado, //ok
                        TempoRestante: resp.data.Tarefa.TempoRestante, //ok
                        TempoUtilizado: resp.data.Tarefa.TempoUtilizado, //ok
                        TempoAdicionado: resp.data.Tarefa.TempoAdicionado, //ok
                        DataAtualizacaoTempoRestante: resp.data.DataAtualizacaoTempoRestante, //NÃO VEM
                        LinkTarefa: resp.data.LinkTarefa, //ok
                        CodigoLabel: resp.data.CodigoLabel, //ok
                        Tarefa: resp.data.Tarefa
                    }

                    const { tasks } = this.state
                    let newTasks = tasks.map(m => {
                        let day = m
                        let data = moment.parseZone(resp.data.DataInclusao).format('YYYY-MM-DD')
                        if (moment().tz(moment.tz.guess()).format('YYYY-MM-DD') === moment.parseZone(resp.data.DataInclusao).format('YYYY-MM-DD')) {

                            day.tasks = day.tasks.map(t => {
                                let task = t
                                task.Iniciada = false
                                //if(moment.)
                                return task
                            })

                            //moment(task.DataInicio.split('T')[0].toString()).diff( moment(moment().format().split('T')[0].toString()), 'minutes')
                            if (moment.parseZone(resp.data.DataInclusao).startOf('day').diff(moment.parseZone(day.data).startOf('day'), 'minutes') === 0) {
                                day.tasks = [...day.tasks, {
                                    ...atividade,
                                    start: atividade.Iniciada,
                                    time: new Date(moment(`${data} 00:00:00`).add(atividade.Tempo, 'minutes')),
                                    StatusAtividade: resp.data.Status
                                }]
                            }
                            /*day.tasks = [
                                ...day.tasks.map( t => {
                                    let stopTasks = t
                                    stopTasks.Iniciada = false
                                    return  stopTasks
                                }),
    
                                {
                                    ...atividade,
                                    start: atividade.Iniciada,
                                    time: new Date(moment(`${data} 00:00:00`).add(atividade.Tempo, 'minutes')),
                                    StatusAtividade: resp.data.Status
                                }
                            ]*/
                        }
                        return day
                    })

                    newTasks = newTasks.map(d => {
                        const times = d && d.tasks.map(t => t.Tempos.filter(s => s.status === "A" || s.status === "S" ||
                            (s.status === "P" && s.Justificativas.filter(j => /*preferencesConfig && preferencesConfig.DONT_PAY_CONTESTEDS ? j.Status !== "J" && j.Status !== "R" :*/ j.Status !== "R")
                            ).length > 0).map(t => {
                                let tempo = t
                                const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                                return rounded.diff
                            })).flat()

                        let day = d
                        day.tasks = d.tasks.map(t => {
                            let task = t
                            if (task.Codigo === atividade.Codigo) {
                                task.Clientes = Tarefa.Clientes
                            }
                            if (task.CodigoTarefa === tarefaSelecionada) {
                                task.TempoUtilizado = atividade.TempoUtilizado
                                task.TempoRestante = atividade.TempoRestante
                            }
                            task.Tempos = task.Tempos.map(r => {
                                let tempos = r
                                if (!r.data_fim && !atividade.Tempos.map(m => m.Codigo).includes(r.Codigo)) {
                                    tempos.data_fim = atividade.Tempos && atividade.Tempos[0] && atividade.Tempos[0].data_inicio
                                }
                                return tempos
                            })
                            return task
                        })
                        day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
                        return day
                    })

                    this.setState({ loading: false, tasks: newTasks, taskRunning: atividade.Codigo, taskRunningO: atividade.CodigoTarefa, date: Tarefa.continueTask ? new Date() : this.state.date }, () => {
                        this.atualizaTempoRestanteAtividades(tarefaSelecionada, atividade.TempoUtilizado, atividade.TempoRestante)
                        this.toggleModal()
                        this.child.autoCloseEditTask()
                        this.atualizaDataVisualizacao(resp.data.DataInclusao)
                    })
                    console.warn(Tarefa)
                    console.warn(params.tracking.length)
                    if (Tarefa.Codigo === 0 && Tarefa.Tempo > 0 && params.tracking.length === 0) {
                        console.warn(`ÌNICIA ATIVIDADE`)
                        this.timer.iniciarAtividade(atividade)
                        //this.timer.atualizaAtividade(Tarefa.Codigo)
                    }


                } else {
                    ToastError(resp.response.data)
                }


            }).catch(error => {
                this.setState({ loading: false })
                const err = errorHandle(error)
                if (err && err.code === 400) {
                    ToastError(err.message)
                    this.child.autoOpenEditTask('O tempo inicial informado está sobrepondo outra atividade')
                } else if (err.code === 403) {
                    //this.toggleModal()
                    this.toggleTrackingModal()
                } else if (err && err.code === 502) {
                    this.setState({ loading: false })
                    this.toggleModal()
                } else {
                    ToastError(err.message)
                }
            })

        /*const { codigo } = this.props.user
        if (projetoTarefaSelecionada.Codigo === 0) {
            ToastError('Selecione um projeto')
            return
        }
        if (tarefaSelecionada.Codigo === 0) {
            ToastError('Selecione uma tarefa')
            return
        }
        if (Tarefa.Descricao === "") {
            ToastError('Inclua uma descrição da tarefa')
            return
        }

        if(Tarefa.TempoTarefa.split(':')[0] > 8){
            ToastError('Sua tarea não pode ter mais de 8 horas')
            return
        }


        if (this.state.Empresa.JustificativaAprovacaoGestor) {
            if (Tarefa.Tempo > Tarefa.OldTempo && (Tarefa.Justificativa.trim() === "" || !Tarefa.Justificativa)) {
                ToastError('Inclua uma justificativa de alteração do tempo')
                return
            }
        }
        Tarefa.codigoPessoa = codigo
        Tarefa.CodigoProjeto = projetoTarefaSelecionada.Codigo
        Tarefa.codigoTarefa = tarefaSelecionada.Codigo

        if (this.state.date.toLocaleDateString() === new Date().toLocaleDateString() || Tarefa.continueTask) {
            Tarefa.DataInclusao = new Date()
        } else {
            Tarefa.DataInclusao = this.state.date
        }

        Tarefa.DataInclusao = Tarefa.DataInclusao.toISOString()

        this.setState({ loading: true })
        const action = Tarefa.Codigo === 0 ? Axios.post : Axios.put
        Tarefa.TempoDia = Tarefa.Tempo

        Tarefa.TempoAlterado = Tarefa.Codigo > 0 && Tarefa.OldTempo !== Tarefa.Tempo

        if (Tarefa.Tempo < 0) {
            ToastError('Tempo não pode ser negativo')
            return
        }

        if (Tarefa.continueTask) {
            if (Tarefa.CodigoAtividadePai === 0 || Tarefa.CodigoAtividadePai === null) {
                Tarefa.CodigoAtividadePai = Tarefa.OldCodigo
            }
            Tarefa.Codigo = Tarefa.OldCodigo
        }

        action(`${url.RouterUrl}/api/back/atividade${Tarefa.continueTask > 0 ? '/clonar' : ''}`, Tarefa)
            .then(resp => {
                if (resp.status === 200 && resp.data.status === "success") {
                    const atividade = resp.data.data
                    Axios.post(`${url.RouterUrl}/api/clock/sync/${atividade.Codigo}/clear`)
                    const tarefas = [...this.state.tasks]
                    const dt = Tarefa.continueTask ? new Date().toLocaleDateString() : this.state.date.toLocaleDateString()
                    const diaTarefa = tarefas.find(x => x.data.toLocaleDateString() === dt)
                    if (diaTarefa) {
                        atividade.Tempo = Tarefa.Tempo

                        let data = new Date(atividade.DataInclusao)
                        TimerUtils.Data.AtualizaHoraMinuto(data, atividade.Tempo)

                        if (Tarefa.Tempo === 0 && data.toLocaleDateString() === new Date().toLocaleDateString()) {

                            this.pausaTarefas()
                        }


                        if (Tarefa.Codigo === 0 || Tarefa.continueTask) {

                            diaTarefa.tasks.push({
                                ...atividade,
                                start: atividade.Iniciada,
                                time: data,
                                StatusAtividade: 'P'
                            })
                        } else {

                            diaTarefa.tasks.forEach(x => {
                                if (x.Codigo === Tarefa.Codigo) {
                                    x.CodigoEmpresa = atividade.CodigoEmpresa
                                    x.CodigoProjeto = atividade.CodigoProjeto
                                    x.CodigoTarefa = atividade.CodigoTarefa
                                    x.Clientes = atividade.Clientes
                                    x.NomeProjeto = atividade.NomeProjeto
                                    x.NomeTarefa = atividade.NomeTarefa
                                    x.Descricao = atividade.Descricao
                                    x.Iniciada = atividade.Iniciada
                                    x.start = atividade.Iniciada
                                    x.Reprovados = atividade.Reprovados
                                    x.Planejado = atividade.Planejado
                                    x.TempoPlanejado = atividade.TempoPlanejado
                                    x.TempoRestante = atividade.TempoRestante
                                    x.TempoUtilizado = atividade.TempoUtilizado
                                    if (Tarefa.TempoAlterado) {
                                        x.time = data
                                    }
                                }
                            })
                        }
                        if (this.state.date.toLocaleDateString() !== new Date().toLocaleDateString()) {
                            this.setState({ tasksLeft: [] })
                            this.carregarTarefasFixadas()
                            this.carregarTarefasNaoFixadas()
                        }
                    }

                    tarefas.forEach(y => {
                        y.tasks.forEach(x => {
                            if (x.Codigo === Tarefa.Codigo) {
                                x.CodigoEmpresa = atividade.CodigoEmpresa
                                x.CodigoProjeto = atividade.CodigoProjeto
                                x.CodigoTarefa = atividade.CodigoTarefa
                                x.Clientes = atividade.Clientes
                                x.NomeProjeto = atividade.NomeProjeto
                                x.NomeTarefa = atividade.NomeTarefa
                                x.Descricao = atividade.Descricao
                            }
                        })
                    })

                    this.state.tasks = [...tarefas]
                    this.atualizaTempoRestanteAtividades(atividade)
                    this.setState({ tasks: [...this.state.tasks], loading: false, date: Tarefa.continueTask ? new Date() : this.state.date })
                    this.toggleModal()
                } else {
                    ToastError(resp.response.data)
                }
            })
            .catch(resp => {
                this.setState({ loading: false })
                ToastError(resp.response.data.message)
            })*/
    }
    /*carregarResumo = async (codigo, di, df) => {
        await Axios.get(`${url.RouterUrl}/api/back/pessoa/${codigo}/resumo/${moment(di).format('YYYY-MM-DD')}/${moment(df).format('YYYY-MM-DD')}`)
            .then(resp => {
                this.setState({
                    Projetos: resp.data.data.Projetos ?? [],
                    Tarefas: resp.data.data.Tarefas ?? [],
                })
            })
            .catch(resp => {
                ToastError(resp.response.data)
            })
    }*/

    textColor(hex) {
        if (typeof hex === 'undefined') {
            hex = "#000"
        }
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
            return r + r + g + g + b + b;
        });
        let rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        rgb = (rgb ? { r: parseInt(rgb[1], 16), g: parseInt(rgb[2], 16), b: parseInt(rgb[3], 16) } : { r: 0, g: 0, b: 0 });
        return '#' + (Math.round(((parseInt(rgb.r) * 299) + (parseInt(rgb.g) * 587) + (parseInt(rgb.b) * 114)) / 1000) > 150 ? "555" : "FFF");
    }

    editarAtividade = (tarefa, times, justificatory, changeTime) => {
        const params = {
            description: tarefa.Descricao,
            inclusionDate: moment(tarefa.DataInclusao).format('YYYY-MM-DD'),
            //time: 0,
            changeTime: changeTime,
            justification: justificatory,
            taskCode: tarefa.CodigoTarefa,
            projectCode: tarefa.CodigoProjeto,
            tracking: times.filter(f => f.start && f.end).map(m => {
                const startTime = m.start.split(':')
                const endTime = m.end.split(':')
                if (m.id) {
                    return {
                        id: m.id,
                        startDate: `${startTime[0]}:${startTime[1]}`,
                        endDate: `${endTime[0]}:${endTime[1]}`,
                        changed: m.changed
                    }
                } else {
                    return {
                        startDate: `${startTime[0]}:${startTime[1]}`,
                        endDate: `${endTime[0]}:${endTime[1]}`
                    }
                }

            })
        }

        Axios.put(`${url.RouterUrl}/api/activity/${tarefa.Codigo}`, params)
            .then(resp => {
                const atividade = {
                    Codigo: resp.data.Codigo, // ok
                    CodigoEmpresa: resp.data.CodigoEmpresa, //ok
                    DataCriacao: resp.data.DataCriacao, //ok
                    DataAtualizacao: resp.data.DataAtualizacao, //NÃO VEM
                    DataExclusao: resp.data.DataExclusao, //NÃO VEM
                    CodigoPessoa: resp.data.CodigoPessoa, //OK
                    CodigoPessaoAlteracao: resp.data.CodigoPessaoAlteracao, //OK
                    CodigoAtividadePai: resp.data.CodigoAtividadePai, //OK
                    DataInclusao: resp.data.DataInclusao, //OK
                    Fixada: resp.data.Fixada, //OK
                    Visivel: resp.data.Visivel, // OK
                    NomePessoa: resp.data.NomePessoa, //NÃO VEM
                    Justificativa: resp.data.Justificativa, //NÃO VEM
                    StatusAtividade: resp.data.StatusAtividade, //NÃO VEM
                    QuantidadeTempoPendente: resp.data.QuantidadeTempoPendente, //NÃO VEM
                    TarefaArquivada: resp.data.TarefaArquivada, //NÃO VEM
                    DataInicio: resp.data.Projeto.DataInicio, //OK (ESTÁ DENTRO DE Projeto)
                    CodigoProjeto: resp.data.CodigoProjeto, //OK
                    CodigoTarefa: resp.data.CodigoTarefa, //OK
                    Clientes: tarefa.Clientes, //OK
                    NomeProjeto: resp.data.Projeto.Nome, //OK
                    NomeTarefa: resp.data.Tarefa.Nome, //OK
                    Descricao: resp.data.Descricao, //OK
                    Tempo: resp.data.Tempo, //OK, MAS ESTÁ VINDO COMO 0 EM UMA TAREFA EDITADA QUE TEM TEMPO, VER COM ALISSON
                    Iniciada: resp.data.Iniciada, //OK
                    start: resp.data.Iniciada, //OK
                    Reprovados: null, //NÃO USA MAIS, POR ISSO COLOCADO COMO NULL
                    Planejado: resp.data.Tarefa.Planejado, //OK
                    TempoPlanejado: resp.data.Tarefa.TempoPlanejado, //OK
                    TempoRestante: resp.data.Tarefa.TempoRestante, //OK
                    TempoUtilizado: resp.data.Tarefa.TempoUtilizado, //OK
                    TempoAdicionado: resp.data.Tarefa.TempoAdicionado, //OK
                    DataAtualizacaoTempoRestante: resp.data.DataAtualizacaoTempoRestante, //OK -- NÃO VEM NO POST
                    LinkTarefa: resp.data.LinkTarefa, //OK
                    CodigoLabel: resp.data.CodigoLabel //OK
                }

                Axios.post(`${url.RouterUrl}/api/clock/sync/${atividade.Codigo}/clear`)
                const index = this.state.tasks.findIndex(f => moment(f.data).format('YYYY-MM-DD') === moment(resp.data.DataCriacao).format('YYYY-MM-DD'))
                let tasks = [...this.state.tasks];
                let task = { ...tasks[index] };
                task.tasks = task.tasks.map(m => {
                    let task = m
                    if (task.Codigo === resp.data.Codigo) {
                        let timeSum = resp.data.Tempos.map(t => t.tempo)
                        timeSum = timeSum.reduce((accum, item) => accum + (item > 0 ? item : 0), 0)
                        task.Tempo = timeSum

                        task.Descricao = atividade.Descricao
                        task.CodigoProjeto = atividade.CodigoProjeto
                        task.CodigoProjeto = atividade.CodigoProjeto
                        task.CodigoTarefa = atividade.CodigoTarefa
                        task.NomeTarefa = atividade.NomeTarefa
                        task.NomeProjeto = atividade.NomeProjeto
                        task.Clientes = atividade.Clientes
                        task.time = new Date(`${moment(m.DataCriacao).format('YYYY-MM-DD')} ${TimerUtils.HoraMinuto.MinutosToHoraMinuto(atividade.Tempo)}`)
                        task.Tempos = resp.data.Tempos
                        //task.Tempo = resp.data.Tempo
                    } else {
                        task.Tempos = task.Tempos
                    }
                    return task
                })
                tasks[index] = task;

                this.setState({ tasks, loading: false, date: tarefa.continueTask ? new Date() : this.state.date }, () => {
                    this.atualizaTempoRestanteAtividades(atividade.CodigoTarefa, atividade.TempoUtilizado, atividade.TempoRestante)
                    this.child.autoCloseEditTask()
                    this.toggleModal()

                })

            }).catch(error => {
                this.setState({ loading: false })
                const err = errorHandle(error)
                if (err && err.code === 400) {
                    ToastError(JSON.stringify(err.message))
                } else if (err.code === 403) {
                    //this.toggleModal()
                    this.toggleTrackingModal()
                }

            })
    }
    novoAtualizaTempos = (time) => {
        //VERIFICA A EXPIRAÇÃO DAS CREDENCIAIS CADA VEZ QUE O TEMPO É ATUALIZADO
        loginExpiration()

        if (this.state.taskRunning && this.state.taskRunning > 0) {
            let tsk = this.state.tasks.find(f => f.tasks.find(t => t.Codigo === this.state.taskRunning))
            tsk = typeof tsk !== 'undefined' ? tsk.tasks.find(f => f.Codigo === this.state.taskRunning) : null
            if (tsk) {
                Axios.post(`${url.RouterUrl}/api/clock/sync/${tsk.Codigo}/clear`)
                Axios.get(`${url.RouterUrl}/api/todo/${tsk.CodigoTarefa}`).then(res => {
                    const newTasks = this.state.tasks.map(d => {
                        const times = d && d.tasks.map(t => t.Tempos.filter(s => s.status === "A" || s.status === "S" ||
                            (s.status === "P" && s.Justificativas.filter(j => /*preferencesConfig && preferencesConfig.DONT_PAY_CONTESTEDS ? j.Status !== "J" && j.Status !== "R" :*/ j.Status !== "R")
                            ).length > 0).map(t => {
                                let tempo = t
                                const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                                return rounded.diff
                            })).flat()
                        let day = d
                        day.tasks = d.tasks.map(t => {
                            let task = t
                            if (task.CodigoTarefa === tsk.CodigoTarefa) {
                                task.TempoUtilizado = res.data.TempoUtilizado + Math.ceil(time / 60)
                                //task.Tarefa.TempoUtilizado = res.data.TempoUtilizado + Math.ceil(time/60)
                                //task.TempoRestante = res.data.TempoUtilizado
                            }

                            if (task.Codigo === tsk.Codigo) {

                            }
                            return task
                        })
                        day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
                        return day
                    })
                    this.setState({
                        tasks: newTasks
                    })
                })
            }

        }
    }

    atualizaTempoRestante = (codigoTarefa, atividade) => {
        const newTasks = this.state.tasks.map(d => {
            const times = d && d.tasks.map(t => t.Tempos.filter(s => s.status === "A" || s.status === "S" ||
                (s.status === "P" && s.Justificativas.filter(j => /*preferencesConfig && preferencesConfig.DONT_PAY_CONTESTEDS ? j.Status !== "J" && j.Status !== "R" :*/ j.Status !== "R")
                ).length > 0).map(t => {
                    let tempo = t
                    const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                    return rounded.diff
                })).flat()

            let day = d
            day.tasks = d.tasks.map(t => {
                let task = t
                if (task.CodigoTarefa === codigoTarefa) {
                    task.TempoUtilizado = task.TempoUtilizado + 1
                    task.TempoRestante = atividade.tempoRestante
                }
                return task
            })
            day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
            return day
        })


        this.setState({ tasks: newTasks })
    }

    getDatesWeek() {
        const { date } = this.state
        const localDate = moment(date).tz(moment.tz.guess()).format()
        const startDay = moment(localDate).tz(moment.tz.guess()).startOf('isoWeek').format()
        const fullweek = [0, 1, 2, 3, 4, 5, 6].map(m => {
            return moment(startDay).tz(moment.tz.guess()).add(m, 'day').startOf('day').format()
        })
        return fullweek


        /*let ndays = []
        let nbeforeDayOfWeek = new Date(localDate)
        let nafterDayOfWeek = new Date(localDate)
        while (nbeforeDayOfWeek.getDay() !== 1)
            nbeforeDayOfWeek = new Date(moment.utc(nbeforeDayOfWeek).add(-1, 'day'))
        while (nafterDayOfWeek.getDay() !== 0)
            nafterDayOfWeek = new Date(moment.utc(nafterDayOfWeek).add(1, 'day'))
        while (moment.utc(nbeforeDayOfWeek).format('YYYY-MM-DD') !== moment.utc(nafterDayOfWeek).format('YYYY-MM-DD')) {
            ndays.push(new Date(moment.utc(nbeforeDayOfWeek).format('YYYY-MM-DD')))
            nbeforeDayOfWeek = new Date(moment.utc(nbeforeDayOfWeek).add(1, 'day'))
        }
        ndays.push(new Date(moment.utc(nbeforeDayOfWeek).format('YYYY-MM-DD')))
        return ndays*/


        /*let days = []
        let beforeDayOfWeek = new Date(date.toGMTString())
        let afterDayOfWeek = new Date(date.toGMTString())
        while (beforeDayOfWeek.getDay() !== 1)
            beforeDayOfWeek = new Date(beforeDayOfWeek.setDate(beforeDayOfWeek.getDate() - 1))
        while (afterDayOfWeek.getDay() !== 0)
            afterDayOfWeek = new Date(afterDayOfWeek.setDate(afterDayOfWeek.getDate() + 1))
        while (beforeDayOfWeek.toDateString() !== afterDayOfWeek.toDateString()) {
            days.push(new Date(beforeDayOfWeek.toGMTString()))
            beforeDayOfWeek = new Date(beforeDayOfWeek.setDate(beforeDayOfWeek.getDate() + 1))
        }
        days.push(new Date(beforeDayOfWeek.toGMTString()))
        return days*/
    }

    somaTempoAtividadesDia(data, format) {
        const dt = data.toLocaleDateString()
        const diaTarefa = this.state.tasks.find(x => x.data.toLocaleDateString() === dt)
        if (!diaTarefa)
            return '0:00'
        diaTarefa.tasks = diaTarefa.tasks ?? []
        let horas = 0
        let minutos = 0
        diaTarefa.tasks.forEach(task => {
            horas += task.time.getHours()
            minutos += task.time.getMinutes()
        })
        let total = horas * 60 + minutos
        return TimerUtils.HoraMinuto.MinutosToHoraMinuto(total)
    }

    somaTotalHoras() {
        const diasSemana = this.getDatesWeek()
        const tasks = this.state.tasks ?? []
        let horas = 0
        let minutos = 0
        diasSemana.forEach(item => {
            const dia = item.toLocaleDateString()
            const diaTarefa = tasks.find(x => x.data.toLocaleDateString() === dia)
            if (diaTarefa && diaTarefa.tasks) {
                diaTarefa.tasks = diaTarefa.tasks ?? []
                diaTarefa.tasks.forEach(task => {
                    horas += task.time.getHours()
                    minutos += task.time.getMinutes()
                })
            }
        })
        let total = horas * 60 + minutos
        return TimerUtils.HoraMinuto.MinutosToHoraMinuto(total)
    }

    atualizaDataVisualizacao(data) {
        const find = this.state.tasks.find(item => {
            return item.data === data
        })
        if (!find || typeof find === 'undefined') {
            this.carregarTarefasUsuario(data)
        }
        this.setState({
            date: data,
            Tarefa: {
                ...this.state.Tarefa,
                DataInclusao: new Date(data)
            }
        })
    }

    renderButtonsWeek() {
        const { date } = this.state
        const days = this.getDatesWeek()
        const selectedDay = moment(date).tz(moment.tz.guess()).weekday()
        if (this.state.tasks.length === 0) {
            return days.map((item, index) => {
                return (<li
                    key={"index-button" + index}
                    className={``}>
                    {/*<b>{weeks[moment.utc(item.data).weekday()]}</b>*/}
                    <span className="hora-button"><Spinner size="sm" /></span>
                </li>)
            })
        } else {
            return this.state.tasks && this.state.tasks.length > 0 && this.state.tasks.map((item, index) => {
                let dayTotal = item.tasks.map(t => t.Tempos.filter(f => paymentsConfigs(f, this.props.user && this.props.user.preferencesConfig)).map(o => { return { start: o.data_inicio, end: o.data_fim } })).flat().map(t => {
                    return timeround(t.start, t.end)
                }).reduce((accum, item) => accum + (item.diff > 0 ? item.diff : 0), 0)

                return (
                    <li
                        key={"index-button" + index}
                        className={`${(selectedDay === moment(item.data).tz(moment.tz.guess()).weekday()) ? 'selected' : ''}`}
                        onClick={() =>
                            this.atualizaDataVisualizacao(moment(item.data).tz(moment.tz.guess()).startOf('day').format())
                        }
                    >
                        {/*JSON.stringify(moment(item.data).tz(moment.tz.guess()).startOf('day').format())*/}
                        {JSON.stringify()}
                        <b>{weeks[moment(item.data).tz(moment.tz.guess()).weekday()]}</b>
                        <span className="hora-button">{secondsToTime(dayTotal)}</span>
                    </li>

                )
            }
            )

        }
    }

    editarTarefa = (task, continueTask) => {

        const times = task.Tempos.filter(s => s.status === "A" || s.status === "S" ||
            (s.status === "P" && s.Justificativas.filter(j => /*preferencesConfig && preferencesConfig.DONT_PAY_CONTESTEDS ? j.Status !== "J" && j.Status !== "R" :*/ j.Status !== "R")
            ).length > 0).map(t => {
                let tempo = t
                const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                return rounded.diff
            })
        const timeTask = times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0)
        continueTask = continueTask ?? false
        let projetoSelecionado = this.state.ProjetosTarefas.find(x => x.Codigo === task.CodigoProjeto)
        projetoSelecionado = projetoSelecionado ?? {
            Codigo: 0,
            Nome: 'Nenhum projeto vinculado',
            NomeCliente: '...',
            Tarefas: []
        }

        let tarefaSelecionada = projetoSelecionado.Tarefas.find(x => x.Codigo === task.CodigoTarefa)
        tarefaSelecionada = tarefaSelecionada ?? {
            Codigo: 0,
            Nome: 'Nenhuma tarefa para esse projeto',
        }
        let timeEditTask = new Date(task.time.getTime())
        let dataInclusao = new Date(task.DataInclusao)
        if (continueTask) {
            timeEditTask.setHours(0)
            timeEditTask.setMinutes(0)
            timeEditTask.setSeconds(0)
            dataInclusao = new Date()
        }
        if (continueTask) {
            timeEditTask.setHours(0)
            timeEditTask.setMinutes(0)
            timeEditTask.setSeconds(0)
            dataInclusao = new Date()
        }
        let horas = '0' + timeEditTask.getHours()
        let minutos = '0' + timeEditTask.getMinutes()
        const time = ((timeEditTask.getHours() * 60) + timeEditTask.getMinutes())
        this.setState({
            Tarefa: {
                ...task,
                //TempoTarefa: horas.substr(horas.length - 2, horas.length) + ":" + minutos.substr(minutos.length - 2, minutos.length),
                TempoTarefa: TimerUtils.HoraMinuto.MinutosToHoraMinuto(timeTask),
                OldTempo: time,
                Tempo: time,
                TempoDia: time,
                DataInclusao: dataInclusao,
                continueTask: continueTask,
                OldCodigo: continueTask ? task.Codigo : 0,
                Codigo: continueTask ? 0 : task.Codigo,
            },
            ProjetoTarefaSelecionado: projetoSelecionado,
            TarefaSelecionada: tarefaSelecionada,
        })
        this.toggleModal()


    }

    adicionarTarefaHoje = (task) => {
        const tasksHoje = this.state.tasks.find(x => x.data.toLocaleDateString() === new Date().toLocaleDateString())
        const data = new Date()
        data.setHours(0)
        data.setMinutes(0)
        tasksHoje.tasks.push({
            ...task,
            Tempo: 0,
            DataInclusao: new Date().toISOString(),
            Iniciada: true,
            time: data
        })
        this.setState({ tasks: [...this.state.tasks] })
    }

    notify = (tarefa, tempo) => {
        if (tarefa.time.getMinutes() !== tempo.getMinutes()) {
            tarefa.time = tempo
            tarefa.TempoRestante--
            this.atualizaTempoRestanteAtividades(tarefa.CodigoTarefa, tarefa.TempoUtilizado, tarefa.TempoRestante)
            this.setState({ tasks: [...this.state.tasks] })
        }
    }
    notifyFinalizado = (tarefa) => {
        const newTasks = this.state.tasks.map(d => {
            let day = d
            day.tasks = day.tasks.map(t => {
                let task = t
                if (tarefa.CodigoTarefa === t.CodigoTarefa) {
                    task.TarefaArquivada = !task.TarefaArquivada
                    //task.Iniciada = tarefa.TarefaArquivada ? false : task.Iniciada
                }
                return task
            })
            return day
        })
        this.setState({ tasks: newTasks })
    }
    notifyTempoRestante = (tarefa, tempo) => {
        tarefa.TempoRestante = tempo
        this.atualizaTempoRestanteAtividades(tarefa.CodigoTarefa, tarefa.TempoUtilizado, tarefa.TempoRestante)
        this.setState({ tasks: [...this.state.tasks] })
    }


    carregarTempoAtividade(atividade) {
        this.setState({ observacao: [] })
        Axios.get(`${url.RouterUrl}/api/back/tempo/${atividade.Codigo}`)
            .then(resp => {
                if (resp.status === 200 && resp.data.status === "success") {
                    this.setState({ observacao: resp.data.data, modalObservacaoTimeline: true, tempoSelecionado: atividade })
                }
            })
    }
    atualizaTempoRestanteAtividades(codigo, tempoUtilizado, tempoRestante) {
        const newTasks = this.state.tasks.map(d => {
            const times = d && d.tasks.map(t => t.Tempos.filter(s => s.status === "A" || s.status === "S" ||
                (s.status === "P" && s.Justificativas.filter(j => /*preferencesConfig && preferencesConfig.DONT_PAY_CONTESTEDS ? j.Status !== "J" && j.Status !== "R" :*/ j.Status !== "R")
                ).length > 0).map(t => {
                    let tempo = t
                    const startTime = moment(tempo.data_inicio).tz(moment.tz.guess())
                    const rounded = timeround(tempo.data_inicio, tempo.data_fim)
                    return rounded.diff
                })).flat()

            let day = d
            day.tasks = d.tasks.map(t => {
                let task = t
                if (task.CodigoTarefa === codigo) {
                    task.TempoUtilizado = tempoUtilizado
                    task.TempoRestante = tempoRestante
                }
                return task
            })
            day.total = day.tasks !== 'undefined' ? times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0) : 0
            return day
        })
        this.setState({ tasks: newTasks })
    }

    subtraiTempoRestanteAtividades(tarefa) {
        this.state.tasks.forEach(t => {
            t.tasks.forEach(task => {
                if (task.CodigoTarefa === tarefa.CodigoTarefa) {
                    task.TempoRestante += tarefa.Tempo
                }
            })
        })
    }

    getTimes = () => {
        const { date, isDisconnected } = this.state
        const find = this.state.tasks.find(item => {
            return moment.parseZone(item.data).startOf('day').diff(moment.parseZone(date).startOf('day'), 'minutes') === 0
        })

        const times = find && find.tasks.map(t => t.Tempos.filter(f => paymentsConfigs(f, this.props.user && this.props.user.preferencesConfig)).map(t => {
            let tempo = t
            let rounded = timeround(tempo.data_inicio, tempo.data_fim)
            rounded.codigo = tempo.Codigo
            rounded.codigo_atividade = tempo.codigo_atividade
            return rounded
        }))
        return times
    }


    renderTasksContent() {
        const { date, isDisconnected } = this.state
        const times = this.getTimes()
        const find = this.state.tasks.find(item => {
            return moment.parseZone(item.data).startOf('day').diff(moment.parseZone(date).startOf('day'), 'minutes') === 0
        })
        let taskRunning = this.state.tasks.find(f => f.tasks.find(f => f.Codigo === this.state.taskRunning))
        taskRunning = taskRunning && taskRunning.tasks ? taskRunning.tasks.find(f => f.Codigo === this.state.taskRunning) : null



        if (find && find.tasks.length > 0) {
            return (
                <div>
                    {find.tasks.map((task, index) => {
                        const clientes = task && task.Projeto && task.Projeto.Clientes ? task.Projeto.Clientes : task.Clientes ? task.Clientes : []
                        const cliente = clientes.map(x => x.Nome).join(',')
                        const { NomeProjeto, NomeTarefa, Descricao, CodigoLabel } = task
                        return (
                            <div className="time-row" key={`grid-content-${new Date().toISOString()}-${index}`} style={index < find.tasks.length - 1 ? { borderBottom: '1px solid var(--minzi-theme-dark-border-color, #ededed)', paddingBottom: '10px', marginBottom: '10px' } : null}>
                                <div className="flex-grow-1">
                                    <div className="flex-grow-1" style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                        <b className={`${task.StatusAtividade !== 'A' ? 'info' : ''} ${task.TarefaArquivada === true ? 'texto-sublinhado' : ''}`}>{NomeProjeto}</b> <span className={`${task.TarefaArquivada === true ? 'texto-sublinhado' : ''}`}>{cliente && `(${cliente})`}</span>
                                        <br />
                                        <div dangerouslySetInnerHTML={{
                                            __html: `<div class="descricao-tarefa card-description ${task.TarefaArquivada === true ? 'texto-sublinhado' : ''}">
                                                                ${NomeTarefa} ${CodigoLabel ? ` <b class="black" style="font-size: 12px"><small>#${CodigoLabel}</small></b>` : ''}
                                                                ${task.LinkTarefa ?
                                                    ` &mdash; <a class='info' style="white-space: pre-wrap; word-break: break-word " target="_blank" href="${task.LinkTarefa}"><img src="${task.Tarefa && task.Tarefa.TipoIntegracao && task.Tarefa.TipoIntegracao === 'azuredevops' && Azure}" width="15" /> ${Descricao} </a>` :
                                                    ``
                                                }
                                                            </div>`
                                        }} />

                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center time-functions">

                                    <TimerTarefa
                                        allowedTimezone={this.props.detalhesUsuario && this.props.detalhesUsuario.Timezone}
                                        onRef={ref => (this.timer = ref)}
                                        ViewGestorPessoa={this.state.ViewGestorPessoa}
                                        task={task}
                                        novoAtualizaTempos={this.novoAtualizaTempos}
                                        editarTarefa={this.editarTarefa}
                                        pausaTarefas={this.pausaTarefas}
                                        adicionarTempo={this.adicionarTempo}
                                        disableDone={task.CodigoTarefa === this.state.taskRunningO && task.Codigo !== this.state.taskRunning}
                                        //tasks={this.state.tasks}
                                        requestInit={(param) => this.setState({ requesting: param })}
                                        requesting={this.state.requesting}
                                        totalTime={times[index].map(m => m.diff).reduce((accum, item) => accum + (item > 0 ? item : 0), 0)}
                                        adicionarTarefaHoje={this.adicionarTarefaHoje}
                                        startAtividade={this.startAtividade}
                                        atualizaTempoRestante={this.atualizaTempoRestante}
                                        notify={this.notify}
                                        toggleHideTask={this.child.toogleHidetask}
                                        notifyTempoRestante={this.notifyTempoRestante}
                                        notifyFinalizado={this.notifyFinalizado}
                                        isDisconnected={isDisconnected}
                                        showModal={(observacao) => {
                                            this.setState({ observacao: observacao, modalObservacao: true, fullObservacoes: false })
                                        }}
                                        toggleDoneTask={this.toggleDoneTask}
                                        toggleTrackingModal={this.toggleTrackingModal}
                                        toggleRunningModal={(running) => {
                                            this.setState({
                                                running: running
                                            })
                                        }}
                                    />
                                    <div className="d-inline-block d-none align-middle">
                                        <If visivel={task.StatusAtividade === 'P'}>
                                            <OverlayTrigger placement='top'
                                                overlay={
                                                    <Tooltip id={'ppover' + index}>
                                                        Editar
                                                    </Tooltip>}>
                                                <Button.Ripple color="flat-primary" className="ml-50 pl-50 pr-50 btn-clock" size="sm"
                                                    disabled={this.state.ViewGestorPessoa !== null || isDisconnected}
                                                    onClick={() => this.editarTarefa(task)}>
                                                    <div className="d-inline-block d-none align-middle">
                                                        <Icon.Edit size={15} />
                                                    </div>
                                                </Button.Ripple>
                                            </OverlayTrigger>
                                        </If>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }
        return (<h4 className="m-3 light">Nenhuma atividade registrada</h4>)
    }
    renderTasksLeft() {
        const { tasksLeft } = this.state
        let tasksFixo = []
        tasksLeft.forEach(t => {
            if (t.fixo) {
                tasksFixo.push(t)
            }
        })
        tasksFixo.sort((a, b) => {
            if (a.codigo < b.codigo) return -1;
            else if (a.codigo > b.codigo) return +1;
            else return 0;
        })
        let tasksNFixo = []
        tasksLeft.forEach(t => {
            if (!t.fixo) {
                tasksNFixo.push(t)
            }
        })
        tasksNFixo.sort((a, b) => {
            if (a.codigo < b.codigo) return -1;
            else if (a.codigo > b.codigo) return +1;
            else return 0;
        })
        return tasksLeft
            .filter(x => this.state.ViewGestorPessoa === null)
            .filter(x => x.fixo)
            .map((item, index) => {
                return (<Alert key={`task-left-${this.state.date}-${index}`} color={item.fixo ? 'warning' : 'light'}>
                    <div className="alert-heading">
                        <span>{`${weeks[item.data.getDay()]} ${months[item.data.getMonth()]} ${item.data.getDate()} ${item.data.getFullYear()}`}</span>
                        <span className="float-right">
                            <Link to="#"
                                onClick={() => this.toggleSetFavorite('desfixar', item.Codigo)}>
                                <Icon.X size={15} className="danger icon-pointer" />
                            </Link>
                            {/*<Link to="#"
                                onClick={() => {
                                    this.atividadeNaoVisivel(item.Codigo)
                                        .then(resp => {
                                            if (!resp.status === 200) {
                                                ToastError(resp.response.data.message)
                                            }
                                        })
                                        .catch(resp => {
                                            ToastError(resp.response.data.message)
                                        })
                                    setTimeout(() => {
                                        const index = this.state.tasksLeft.map(x => x.Codigo).indexOf(item.Codigo)
                                        if (index >= 0) {
                                            this.state.tasksLeft.splice(index, 1)
                                            this.setState({ tasksLeft: [...this.state.tasksLeft] })
                                        }
                                    }, 100);
                                }}>
                                <Icon.X size={15} className="danger icon-pointer" />
                            </Link>*/}
                            {/*<Link to="#"
                                onClick={() => {
                                    const action = !item.fixo ? this.fixarAtividade : this.desfixarAtividade
                                    action(item.Codigo)
                                        .then(resp => {
                                            if (!resp.status === 200) {
                                                ToastError(resp.response.data.message)
                                            }
                                        })
                                        .catch(resp => {
                                            ToastError(resp.response.data.message)
                                        })

                                    setTimeout(() => {
                                        item.fixo = !item.fixo
                                        this.setState({ tasksLeft: [...this.state.tasksLeft] })
                                    }, 100);
                                }} >
                                <Icon.Star size={15} className={'icon-pointer ' + (item.fixo ? 'warning' : 'light')} />
                            </Link>*/}
                        </span>
                    </div>
                    <table className='w-100'>
                        <tbody>
                            <tr>
                                <td>
                                    {item.NomeTarefa} - {item.Descricao.length > 30 ? item.Descricao.substr(0, 29) + '...' : item.Descricao}
                                </td>
                                <td className='text-right'>
                                    <Link to="#"
                                        onClick={() => {
                                            let data = new Date(item.DataInclusao)
                                            TimerUtils.Data.AtualizaHoraMinuto(data, item.Tempo)
                                            item.time = data
                                            this.editarTarefa(item, true)
                                        }}>
                                        <Icon.Clock size={18} className="black icon-pointer" />
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Alert>)
            })
    }

    handleOpenHelp = () => {
        this.setState(prevState => ({
            showHelp: !prevState.showHelp
        }))
    }
    handleOpenTour = () => {
        this.setState({ isOpenTour: true })
    }
    renderGantt = () => {
        const dayGantt = this.state.tasks.filter(f => moment(f.data).format('YYYY-MM-DD') === moment(this.state.date).format('YYYY-MM-DD')).filter(f => f.total > 0)
        let GMT = moment().tz(moment.tz.guess()).toString().split('GMT')[1]
        GMT = `${GMT.substr(0, 3)}:${GMT.substr(GMT.length - 2)}`

        return (
            <div>
                {dayGantt.length > 0 ?
                    <div className="w-100">
                        {dayGantt.map(m => {

                            let task = m
                            task.tasks = task.tasks.map(t => {
                                let tk = t
                                tk.Status = t.StatusAtividade
                                tk.CodigoAtividade = t.Codigo
                                tk.split = t.Split
                                tk.Tempos = t && t.Tempos && t.Tempos.map(o => {
                                    let start = o.data_inicio.substr(0, 19)
                                    start = `${start}${GMT}`

                                    //let end = o.data_fim ? o.data_fim.substr(0, 19) :  moment().tz(moment.tz.guess()).format().toString().substr(0, 19)
                                    let end = o.data_fim ? o.data_fim.substr(0, 19) : moment().tz(moment.tz.guess()).format().toString().substr(0, 19)
                                    end = `${end}${GMT}`
                                    let temp = o
                                    temp.CodigoAtividade = o.codigo_atividade
                                    temp.CodigoPessoa = o.codigo_pessoa
                                    temp.DataFim = end
                                    temp.DataInicio = start
                                    temp.Status = o.status
                                    temp.Tempo = o.tempo
                                    temp.Tipo = o.tipo
                                    return temp
                                })
                                return tk
                            })
                            return task

                        }).map((item, index) => {

                            //const data = this.toDate(item.data)
                            const dayTimes = item.tasks.map(m => m.Tempos.filter(t => t.status !== "C" && t.status !== "R").map(t => { return { start: t.DataInicio, end: t.DataFim } })).flat()
                            const dayFirstTime = dayTimes.sort((a, b) => moment(a.start) - moment(b.start))[0]
                            const dayLastTime = dayTimes.sort((a, b) => moment(a.end) - moment(b.end))[dayTimes.length - 1]
                            const dayStart = dayFirstTime.start
                            const dayEnd = dayLastTime && dayLastTime.end ? dayLastTime.end : moment().format('YYYY-MM-DD HH:MM:ss')


                            let GMT = moment.parseZone(dayStart).toString().split('GMT')[1]
                            GMT = `${GMT.substr(0, 3)}:${GMT.substr(GMT.length - 2)}`
                            const roundedStart = dayStart ? parseInt(moment.parseZone(dayStart).format('ss')) > 30 ? moment.parseZone(dayStart).startOf('minute').add(1, 'minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : moment.parseZone(dayStart).startOf('minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : null
                            const roundedEnd = dayEnd ? parseInt(moment.parseZone(dayEnd).format('ss')) > 30 ? moment.parseZone(dayEnd).startOf('minute').add(1, 'minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : moment.parseZone(dayEnd).startOf('minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : null

                            const jornada = `${moment(roundedStart).format('HH:mm')} - ${moment(roundedEnd).format('HH:mm')} (${TimerUtils.HoraMinuto.MinutosToHoraMinuto(moment(roundedEnd).diff(moment(roundedStart), 'minutes'))})`

                            const times = dayTimes.map(tempo => {
                                /*const startTime = moment(t.start).tz(moment.tz.guess())
                                const endTime = !t.end ? moment(new Date()).tz(moment.tz.guess()) : moment(t.end).parseZone()
                                    return Math.round(moment(endTime).diff(startTime, 'seconds') / 60)*/
                                const rounded = timeround(tempo.start, tempo.end)
                                return rounded.diff
                            })
                            const timeTask = times.reduce((accum, item) => accum + (item > 0 ? item : 0), 0)
                            return (
                                <div key={`gantt_${index}`} className="">
                                    <Gantt
                                        user={this.state.ViewGestorPessoa?.codigo}
                                        onRef={ref => (this.gantt = ref)}
                                        codigo={this.props.user.codigo}
                                        tracking={this.state[`tracking_date_${moment.parseZone(this.state.date).format('YYYY-MM-DD')}`]}
                                        //timeline={item.Timeline}
                                        tasks={item.tasks}
                                        //colors={item.Colors}
                                        workTime={jornada}
                                        date={moment.parseZone(item.data).format()}
                                        totalTime={item.Total > 0 ? item.Total : timeTask}
                                        borderTop={index > 0 ? true : false}
                                    //handleGanttActions={this.handleGanttActions}
                                    //admin={true}
                                    />
                                </div>)
                        })
                        }
                    </div>
                    :
                    <h4 className="m-3 light">Nenhuma atividade registrada</h4>
                }
            </div>

        )
    }

    totalHoraProgress(weekTotal) {
        const { detalhesUsuario } = this.props
        const capacidade = detalhesUsuario.CapacidadeSemana ? detalhesUsuario.CapacidadeSemana * 60 : 0

        const diasTrabalhados = this.state.tasks.filter(f => f.tasks.length > 0).length
        const diaDaSemana = moment().isoWeekday()
        const horasTrabalhadas = weekTotal

        const percentual = parseFloat(horasTrabalhadas / capacidade) * 100
        const calc = calcDate(this.state.dataFim)
        const proporcional = parseFloat(((horasTrabalhadas) / calc) / (capacidade / 5)) * 100
        return (
            <React.Fragment>
                <Progress
                    color={proporcional > 110 ? 'danger' : proporcional > 90 ? 'info' : proporcional > 80 ? 'warning' : 'danger'}
                    value={percentual} className="progress-xl" >
                    <b>{percentual.toFixed(2)}{" %"}</b>
                </Progress>
            </React.Fragment>
        )
    }

    renderProjetos() {
        if (this.state.Projetos.length === 0)
            return (<div>Nenhuma atividade executada</div>)
        let renderProjetos = () => {
            return this.state.Projetos.map((item, index) => {
                return (
                    <Row key={`p-${index}`}>
                        <Col md='12'>
                            <div className="d-flex align-items-center" style={{ fontSize: 15, marginBottom: 5 }}>
                                <div>
                                    <Avatar color={colors[index]} size="sm" />
                                </div>
                                <div className="flex-grow-1">
                                    {item.Nome}
                                </div>
                                <div>
                                    <b>{TimerUtils.HoraMinuto.MinutosToHoraMinuto(item.Total)}</b>
                                </div>
                            </div>
                            {/*<table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '1rem' }}>
                                            <Avatar color={colors[index]} size="sm" />
                                        </td>
                                        <td className='text-left'>
                                            <h4>{item.Nome}</h4>
                                        </td>
                                        <td className='text-right'>
                                            <h4>{TimerUtils.HoraMinuto.MinutosToHoraMinuto(item.Total)}</h4>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>*/}
                        </Col>
                    </Row>
                )
            })
        }
        let renderProgress = () => {
            const { detalhesUsuario } = this.props
            const capacidade = detalhesUsuario.CapacidadeSemana ? detalhesUsuario.CapacidadeSemana * 60 : 0

            return this.state.Projetos.map((item, index) => {
                const total = (item.Total / (capacidade * 60)) * 100
                return (
                    <Progress key={`progress-p-${index}`} bar color={colors[index]} value={total} />
                )
            })
        }
        return (<div>
            <Progress multi className="progress-xl" >
                {renderProgress()}
            </Progress >
            {renderProjetos()}
        </div>)
    }

    renderTarefas() {
        if (this.state.Tarefas.length === 0)
            return (<div>Nenhuma atividade executada</div>)
        let renderTarefas = () => {
            return this.state.Tarefas.map((item, index) => {
                return (
                    <Row key={`p-${index}`}>
                        <Col md='12'>
                            <div className="d-flex align-items-center" style={{ fontSize: 15, marginBottom: 5 }}>
                                <div>
                                    <Avatar color={colors[index]} size="sm" />
                                </div>
                                <div className="flex-grow-1">
                                    {item.Nome}
                                </div>
                                <div>
                                    <b>{TimerUtils.HoraMinuto.MinutosToHoraMinuto(item.Total)}</b>
                                </div>
                            </div>
                            {/*<table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '1rem' }}>
                                            <Avatar color={colors[index]} size="sm" />
                                        </td>
                                        <td className='text-left'>
                                            <h4>{item.Nome}</h4>
                                        </td>
                                        <td className='text-right'>
                                            <h4>{TimerUtils.HoraMinuto.MinutosToHoraMinuto(item.Total)}</h4>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>*/}
                        </Col>
                    </Row>
                )
            })
        }

        let renderProgress = () => {
            const { detalhesUsuario } = this.props
            const capacidade = detalhesUsuario.CapacidadeSemana ? detalhesUsuario.CapacidadeSemana * 60 : 0

            return this.state.Tarefas.map((item, index) => {
                const total = (item.Total / capacidade) * 100
                return (
                    <Progress key={`progress-t-${index}`} bar color={colors[index]} value={total} />
                )
            })
        }
        return (<div>
            <Progress multi className="progress-xl">
                {renderProgress()}
            </Progress>
            {renderTarefas()}
        </div>)
    }

    carregaTrakingMouseTeclado = (pessoa, data) => {
        Axios.get(`${url.RouterUrl}/api/tracking/${pessoa}/${data}`)
            .then(resp => {
                if (resp.status === 200) {
                    if (typeof this.state.trackings.find(f => f.date === data) === 'undefined') {
                        this.setState({
                            [`tracking_date_${data}`]: { date: data, tracking: resp.data }
                        })

                    }
                }
            }).catch(err => {

            })
    }



    render() {
        const { date, isOpenTour, isDisconnected } = this.state

        //CÁLCULO DO TOTAL DO DIA
        let dayTotal = this.state.tasks.find(f => moment(f.data).format('YYYY-MM-DD') === moment(this.state.date).format('YYYY-MM-DD'))
        dayTotal = dayTotal && dayTotal.tasks && dayTotal.tasks.map(t => t.Tempos
            .filter(f => paymentsConfigs(f, this.props.user && this.props.user.preferencesConfig))
            .map(o => { return { start: o.data_inicio, end: o.data_fim } })).flat().map(t => {
                return timeround(t.start, t.end)
            }).reduce((accum, item) => accum + (item.diff > 0 ? item.diff : 0), 0)


        let weekTotal = this.state.tasks
        weekTotal = weekTotal && weekTotal.map(t => t.tasks) && weekTotal.map(t => t.tasks).flat().map(t => t.Tempos
            .filter(f => paymentsConfigs(f, this.props.user && this.props.user.preferencesConfig))
            .map(o => { return { start: o.data_inicio, end: o.data_fim } })).flat().map(t => {
                return timeround(t.start, t.end)
            }).reduce((accum, item) => accum + (item.diff > 0 ? item.diff : 0), 0)


        const find = this.state.tasks.filter(item => {
            return moment.parseZone(item.data).startOf('day').diff(moment.parseZone(date).startOf('day'), 'minutes') === 0
        })
        let pessoas = this.props.pessoas ?? []

        return (
            <div>
                <PageVisibility onChange={this.handleVisibilityChange} />
                {isDisconnected &&
                    <div className="is-disconnected">
                        Você está offline. As atualizações das suas atividades serão entregues quando você estiver online novamente.
                    </div>
                }
                <Row>
                    {/*<small style={{position: 'absolute', top: '-40px', right: 0}} className="mr-m primary cursor-pointer" id="suricatoo-helper" onClick={this.handleOpenHelp}><Icon.HelpCircle size={15} /> Ajuda</small>*/}
                    <Col md='12'>
                        <div className="time-page-sidebar w-100">
                            {this.state.perfil && (
                                this.state.perfil.Permissao === 'D' ||
                                this.state.perfil.Permissao === 'A' ||
                                this.state.perfil.Permissao === 'G') && (
                                    <div className='w-100 d-flex justify-content-between mt-1'>
                                        <div></div>
                                        <Row>
                                            <Col md="12">
                                                <label>Mudar Visualização</label>
                                                <Select
                                                    isClearable
                                                    name="colors"
                                                    placeholder="Procurar"
                                                    options={pessoas.map(item => {
                                                        return {
                                                            label: (
                                                                <React.Fragment>
                                                                    <If visivel={item.Codigo === 0}>
                                                                        {item.Nome}
                                                                    </If>
                                                                    <If visivel={item.Codigo > 0}>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="d-inline-block d-none align-middle">
                                                                                <AvatarPessoa size="xs" codigo={item.Codigo} nome={item.Nome} />
                                                                            </div>
                                                                            <div className="flex-grow-1 truncate" style={{ maxWidth: 'calc(100% - 25px' }}>
                                                                                {/*<b>{item.Nome.length > 20 ? item.Nome.substr(0, 20) + '...' : item.Nome}</b>*/}
                                                                                {item.Nome}
                                                                            </div>

                                                                        </div>
                                                                    </If>
                                                                </React.Fragment>
                                                            ),
                                                            value: item.Nome,
                                                            codigo: item.Codigo
                                                        }
                                                    })}
                                                    className="projects-sprints-select mb-1"
                                                    classNamePrefix="select"
                                                    isDisabled={isDisconnected}
                                                    onChange={e => {
                                                        this.setState({ ViewGestorPessoa: e })
                                                        this.carregarTarefasUsuario(this.state.date, e)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            {/* <Row>
                            <Col md="12">
                                <Button.Ripple block color="success" className="btn-block"
                                    disabled={
                                        this.state.requesting > 0 ||
                                        moment(date).diff(moment.tz(moment.tz.guess()).startOf('day').format(), 'days') > 0 ||
                                        this.state.ViewGestorPessoa ||
                                        !AllowAddActivity ||
                                        isDisconnected}
                                    onClick={() => {
                                        this.setState({ Tarefa: TarefaVazia }, () => this.toggleModal())

                                    }}>
                                    <Icon.Plus size={18} />
                                    <span>Nova atividade</span>
                                </Button.Ripple>
                                <hr style={{ marginTop: 18, marginBottom: 18 }} />
                            </Col>                            
                        </Row> */}
                        </div>
                        <div className="d-flex w-100 align-items-center mb-1">
                            <div>
                                <ButtonGroup className="mb-0">
                                    <Button.Ripple outline color="primary" size="sm"
                                        disabled={isDisconnected}
                                        onClick={() => {
                                            this.atualizaDataVisualizacao(moment(date).tz(moment.tz.guess()).startOf('day').add(-1, 'week').startOf('isoWeek').format())
                                        }}>
                                        <Icon.ChevronLeft size={15} />
                                    </Button.Ripple>
                                    <Button.Ripple outline color="primary" size="sm"
                                        disabled={isDisconnected}
                                        onClick={() => {
                                            this.atualizaDataVisualizacao(moment(date).tz(moment.tz.guess()).startOf('day').add(1, 'week').startOf('isoWeek').format())
                                        }}>
                                        <Icon.ChevronRight size={15} />
                                    </Button.Ripple>
                                </ButtonGroup>
                            </div>
                            <div className="flex-grow-1 text-center">
                                <h3 className="mr-50 ml-50">{this.state.width > 728 ? `${weeks[moment(date).tz(moment.tz.guess()).weekday()]}, ${moment(date).tz(moment.tz.guess()).format('DD')} de ${months[moment(date).tz(moment.tz.guess()).month()]} de ${moment(date).tz(moment.tz.guess()).year()}` :
                                    moment(date).tz(moment.tz.guess()).format('DD/MM/YYYY')
                                }</h3>
                            </div>
                            <div>
                                <Button.Ripple
                                    outline={moment.parseZone(date).diff(moment.tz(moment.tz.guess()).startOf('day').format(), 'days') !== 0}
                                    disabled={moment.parseZone(date).diff(moment.tz(moment.tz.guess()).startOf('day').format(), 'days') === 0 || isDisconnected}
                                    color="primary" size="sm"
                                    onClick={() => this.atualizaDataVisualizacao(moment().tz(moment.tz.guess()).startOf('day').format())}>
                                    <Icon.Calendar size={15} />
                                    {" "}
                                    <b>Hoje</b>
                                </Button.Ripple>
                            </div>
                        </div>

                        <Row className="justify-content-center">
                            <Col md="12">
                                <ul className="days-header w-100">
                                    {this.renderButtonsWeek()}
                                    <li>
                                        Total: <b>{/*this.somaTotalHoras()*/TimerUtils.HoraMinuto.MinutosToHoraMinuto(weekTotal)}</b>
                                    </li>
                                </ul>
                            </Col>
                            <Col md="12" className="mt-1">
                                <Nav tabs >
                                    <NavItem>
                                        <NavLink
                                            className={`${this.state.activeTab === 1 ? 'active' : ''}`}
                                            onClick={() => {
                                                this.toggleTab(1)
                                            }}>
                                            <Icon.List size={15} />
                                        </NavLink>
                                    </NavItem>
                                    {this.state.perfil?.Permissao !== 'U' &&
                                        <NavItem>
                                            <NavLink
                                                className={`${this.state.activeTab === 2 ? 'active' : ''}`}
                                                onClick={() => {
                                                    this.toggleTab(2)
                                                }}>
                                                <Icon.PieChart size={15} />
                                            </NavLink>
                                        </NavItem>}
                                </Nav>
                            </Col>
                            <Col md="12">
                                {this.state.activeTab === 1 ?
                                    this.renderTasksContent()
                                    : this.state.activeTab === 2 ?
                                        this.renderGantt()
                                        : null
                                }
                            </Col>

                        </Row>
                        <If visivel={find.length > 0}>
                            <Row className="justify-content-between mt-2">
                                <Col md="auto"></Col>
                                <Col md="auto">
                                    <div className="d-inline-block d-none align-middle">
                                        <h3 className="light">Total:</h3>
                                    </div>
                                    {' '}
                                    <div className="d-inline-block d-none align-middle">
                                        {/*<h1>{this.somaTempoAtividadesDia(this.state.date, ':')}</h1>*/}
                                        <h1>{secondsToTime(dayTotal ? dayTotal : 0)}</h1>
                                    </div>
                                </Col>
                            </Row>
                        </If>
                    </Col>
                </Row>
                <ModalEditarTarefa
                    allowedTimezone={this.props.detalhesUsuario && this.props.detalhesUsuario.Timezone}
                    modal={this.state.modal}
                    user={this.props.user}
                    times={this.getTimes()}
                    empresa={this.state.Empresa}
                    tarefa={this.state.Tarefa}
                    date={this.state.date}
                    salvarAtividade={this.salvarAtividade}
                    editarAtividade={this.editarAtividade}
                    deletarAtividade={this.deletarAtividade}
                    projetoTarefaSelecionado={this.state.ProjetoTarefaSelecionado}
                    tarefaSelecionada={this.state.TarefaSelecionada}
                    requesting={this.state.requesting}
                    onRef={ref => (this.child = ref)}
                    isDisconnected={isDisconnected}
                    fecharModal={() => {
                        this.setState({ modal: false })
                    }} />
                <ToastContainer />
                <Modal
                    isOpen={this.state.modalObservacao}
                    toggle={() => this.setState({ modalObservacao: false })}
                    className="modal-dialog-centered modal-lg">
                    <ModalHeader toggle={() => this.setState({ modalObservacao: false })}>
                        {this.state.editarJustificativa ? 'Editar Justificativa' : 'Detalhe de tempos da atividade'}
                    </ModalHeader>
                    <ModalBody className="mh-60">
                        <If visivel={this.state.editarJustificativa}>
                            <Row>
                                <Col md="12">
                                    <Row>
                                        <Col md="12">
                                            <Input type="textarea" rows='5'
                                                value={this.state.Justificativa}
                                                onChange={e => this.setState({ Justificativa: e.target.value })} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="12" className="mb-1 mt-1">
                                    <Button.Ripple color="success" onClick={() => {
                                        Axios.put(`${url.RouterUrl}/api/back/tempo/${this.state.tempoSelecionado.Codigo}/alterarjustificativa`, {
                                            justificativa: this.state.Justificativa
                                        }).then(() => {

                                            let tempo = { ...this.state.tempoSelecionado }
                                            if (tempo.Status === 'R') {
                                                tempo.Status = 'P'
                                                tempo.Justificativa = this.state.Justificativa
                                                tempo.JustificativaGestor = ''
                                                this.state.observacao.push(tempo)

                                                ToastSuccess('Nova justificativa enviada com sucesso')
                                            } else {
                                                this.state.tempoSelecionado.Justificativa = this.state.Justificativa

                                                ToastSuccess('Justificativa alterada com sucesso')
                                            }
                                            this.setState({ editarJustificativa: false, observacao: [...this.state.observacao] })
                                        })
                                    }}>
                                        <Icon.Save size={18} /> Salvar
                                    </Button.Ripple>
                                    <Button.Ripple color="flat-primary" className='ml-1' onClick={() => this.setState({ editarJustificativa: false })}>
                                        <Icon.X size={18} /> Cancelar
                                    </Button.Ripple>
                                </Col>
                            </Row>
                        </If>
                        <If visivel={!this.state.editarJustificativa}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Tempo</th>
                                        <th>Justificativa Colaborador</th>
                                        <th>Resposta Gestor</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.observacao.filter(x => x.Status === 'P' || x.Status === 'R').map((x, index) => {
                                        return (
                                            <tr key={`reprovacoes-${new Date().getTime()}-${index}`}>
                                                <td >{TimerUtils.HoraMinuto.MinutosToHoraMinuto(x.Tempo)}</td>
                                                <td >
                                                    <If visivel={x.Status !== 'A' && x.Status !== 'AE' && this.state.ViewGestorPessoa === null}>
                                                        <Button.Ripple color="flat-primary" className="btn-clock" size="sm"
                                                            onClick={() => this.setState({ editarJustificativa: true, tempoSelecionado: x, Justificativa: x.Justificativa })}>
                                                            <div className="d-inline-block d-none align-middle">
                                                                <Icon.Edit size={15} />
                                                            </div>
                                                        </Button.Ripple>
                                                    </If>
                                                    {x.Justificativa}</td>
                                                <td >{x.JustificativaGestor}</td>
                                                <td className={x.Status === 'R'
                                                    ? 'danger text-bold-600'
                                                    : 'text-bold-600'}>{
                                                        (x.Status === 'A' || x.Status === 'T' || x.Status === 'S')
                                                            ? 'Aprovado'
                                                            : x.Status === 'R' || x.Status === 'C' || x.Status === 'J'
                                                                ? 'Reprovado'
                                                                : 'Pendente'
                                                    }</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </If>
                    </ModalBody>
                </Modal>
                <LoadingPost modal={this.state.loading} />
                <ModalObservacoes
                    modal={this.state.modalObservacaoTimeline}
                    toggleModal={this.toggleModalObservacoes}
                    observacao={this.state.observacao}
                    tempoSelecionado={this.state.tempoSelecionado}
                    aprovar={true}
                    reload={() => {
                        // this.carregarResumo(this.state.Codigo, this.state.dataInicio, this.state.dataFim)
                        // this.props.carregarPendencias()
                    }}
                />
                <Modal
                    isOpen={this.state.modalTags}
                    toggle={this.toggleModalTags}
                    className="modal-dialog-centered">
                    <ModalHeader toggle={this.toggleConfirmRemove} className="bg-info">
                        {this.state.titleTodoTags}
                    </ModalHeader>
                    <ModalBody className="modal-dialog-centered">
                        <Row style={{ width: '100%' }}>
                            <Col md='12' className='mb-1'>
                                <InputSearch value={this.state.searchTags} onChange={e => this.setState({ searchTags: e.target.value })} />
                            </Col>
                            <PerfectScrollbar
                                className="content-rules2"
                                options={{
                                    wheelPropagation: false,
                                }}>
                                {Object.keys(this.state.tagsSelected)
                                    .filter(x => x.toUpperCase().indexOf(this.state.searchTags.toUpperCase()) >= 0)
                                    .map(item => (<Col md='12' className='labels-checkbox-bottom'>
                                        <Checkbox
                                            color="warning"
                                            icon={<Icon.Check className="vx-icon" size={16} />}
                                            label={
                                                (<span className='badge badge-pill' key={`tagsModal-${item.trim()}`}
                                                    style={{
                                                        backgroundColor: this.state.tags[item.trim()],
                                                        color: this.textColor(this.state.tags[item.trim()]),
                                                        marginRight: '2px'
                                                    }}
                                                ><b>{item}</b>
                                                </span>)
                                            }
                                            onChange={e => {
                                                let tags = this.state.todoSelectedTags.Tags.split(";")
                                                if (e.target.checked) {
                                                    tags.push(`${item.trim()}`)
                                                } else {
                                                    tags = tags.filter(x => x !== item.trim())
                                                }
                                                this.state.todoSelectedTags.Tags = tags.join(";")
                                                this.setState({ todoSelectedTags: this.state.todoSelectedTags })
                                                Axios.put(`${url.RouterUrl}/api/todo/${this.state.todoSelectedTags.Codigo}/${this.state.activityCodigoSelectedTags}/tag`, { "tags": this.state.todoSelectedTags.Tags })
                                            }}
                                            checked={this.state.todoSelectedTags.Tags.split(';').filter(x => x === item.trim()).length > 0}
                                        />
                                    </Col>))}
                            </PerfectScrollbar>
                        </Row>

                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.running ? true : false}
                    toggle={this.toggleRunningModal}
                    className="modal-dialog-centered">
                    <ModalHeader toggle={this.toggleConfirmRemove} className="bg-warning">
                        Tarefa em execução
                    </ModalHeader>
                    <ModalBody className="modal-dialog-centered">
                        <p>Não é possível iniciar essa atividade no momento. Essa tarefa está sendo <b>executada por {this.state.running}</b>.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="warning" onClick={this.toggleRunningModal}>
                            Ok
                        </Button>{" "}
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.doneTask ? true : false}
                    toggle={this.toggleDoneTask}
                    className="modal-dialog-centered">
                    <ModalHeader className="bg-warning">
                        Tarefa finalizada
                    </ModalHeader>
                    <ModalBody className="modal-dialog-centered">
                        <p>Essa tarefa foi finalizada e não é possível iniciá-la à partir de dias anteriores.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="warning" onClick={this.toggleDoneTask}>
                            Ok
                        </Button>{" "}
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.trackingModal ? true : false}
                    toggle={this.toggleTrackingModal}
                    className="modal-dialog-centered">
                    <ModalHeader toggle={this.toggleConfirmRemove} className="bg-warning">
                        Faça login na aplicação desktop
                    </ModalHeader>
                    <ModalBody className="modal-dialog-centered">
                        <p>Por favor, faça login e mantenha-o ativo no app Minzi Tracker para adicionar ou iniciar atividades.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="warning" onClick={this.toggleTrackingModal}>
                            Ok
                        </Button>{" "}
                    </ModalFooter>
                </Modal>
                <Help show={this.state.showHelp} position="right" topics={helpTopics} handleOpenHelp={this.handleOpenHelp} />
                <Wizard show={this.state.showWizard} onWizardFinished={event => this.handleOpenTour()} />
                <TourComponent open={isOpenTour} />
                {/*find && find.map( tasks =>
                    <table key={tasks.data} className="table">
                        <tr>
                            <td>{moment(tasks.data).format()}</td>
                            <td>Tempo Int Back</td>
                        </tr>
                        {tasks.tasks.map( ts =>
                            <tr>
                                <td>{ts.NomeTarefa} - {ts.Descricao}</td>
                                <td>
                                    <table>
                                        <tr>
                                            <td>Inicio</td>
                                            <td>Fim</td>
                                            <td>Cálculo Direto</td>
                                            <td>Arredondamento Front</td>
                                            <td>Tempo</td>
                                        </tr>
                                        {ts.Tempos.map( t => {
                                                const dayStart = t.data_inicio
                                                const dayEnd = t.data_fim

                                                let GMT = moment.parseZone(dayStart).toString().split('GMT')[1]
                                                GMT = `${GMT.substr(0, 3)}:${GMT.substr(GMT.length - 2)}`
                                                const roundedStart = dayStart ? parseInt(moment.parseZone(dayStart).format('ss')) > 30 ? moment.parseZone(dayStart).startOf('minute').add(1, 'minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : moment.parseZone(dayStart).startOf('minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : null
                                                const roundedEnd = dayEnd ? parseInt(moment.parseZone(dayEnd).format('ss')) > 30 ? moment.parseZone(dayEnd).startOf('minute').add(1, 'minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : moment.parseZone(dayEnd).startOf('minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : null


                                               return(<tr>
                                                    <td>{moment.parseZone(t.data_inicio).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                    <td>{moment.parseZone(t.data_fim).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                    <td>{moment(t.data_fim).diff(moment(t.data_inicio), 'minutes')}</td>
                                                    <td>{timeround(t.data_inicio, t.data_fim).diff}</td>
                                                    <td>{t.tempo}</td>
                                                </tr>)
                                            }
                                        )}
                                        <tr>
                                            <td colSpan={4}>Condensado back: {ts.Tempo}</td>
                                        </tr>

                                    </table>
                                </td>
                            </tr>
                        )}
                        <tr></tr>
                    </table>
                )*/}


            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.login.user,
        detalhesUsuario: state.cache.pessoa.DetalhesUsuario,
        pessoas: state.cache.pessoa.pessoas,
        trackingEvent: state.trackingEvent.trackingEvent.trackingEvent
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    carregarPessoas,
    trackingEventRemove,
    logout
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Tempo)
