import React, { Component } from 'react'
import Task16Icon from '@atlaskit/icon-object/glyph/task/16'
import Subtask16Icon from '@atlaskit/icon-object/glyph/subtask/16'
import Bug16Icon from '@atlaskit/icon-object/glyph/bug/16'
import Story16Icon from '@atlaskit/icon-object/glyph/story/16'
import Epic16Icon from '@atlaskit/icon-object/glyph/epic/16'
import Changes16Icon from '@atlaskit/icon-object/glyph/changes/16'
import Button from '@atlaskit/button/standard-button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Tooltip from '@atlaskit/tooltip';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import { Cache } from '../../cache/cache'

const app = JSON.parse(localStorage.getItem('_suricatoo_app'))
const user = Cache.user().get()
let accessToken = user?.access_token ?? ''
if (accessToken !== '') {
    accessToken = `?p=${accessToken}`
}

export default class DropdownChangeCardType extends Component {

    render() {
        const { cardKey, name, cardType, subCards } = this.props
        const icon = cardType === 'task' ? <Task16Icon /> :
            cardType === 'subtask' ? <Subtask16Icon /> :
                cardType === 'bug' ? <Bug16Icon /> :
                    cardType === 'history' ? <Story16Icon /> :
                        cardType === 'impediment' ? <Changes16Icon /> : <Epic16Icon />
        const items = [
            { label: <><Task16Icon /> Tarefa</>, value: 'task' },
            { label: <><Subtask16Icon /> Sub tarefa</>, value: 'subtask' },
            { label: <><Bug16Icon /> Bug</>, value: 'bug' },
            { label: <><Changes16Icon /> Desvio</>, value: 'impediment' },
            { label: <><Story16Icon /> História</>, value: 'history' },
            { label: <><Epic16Icon /> Épico</>, value: 'epic' },
        ]
        return (
            <div className='d-flex card-link-item'>
                <Tooltip content='Tarefa - Alterar tipo do item' position='top'>
                    <DropdownMenu
                        trigger={({ triggerRef, testId, isSelected, ...props }) => (
                            <Button className='atl-button card-btn-tipo-item'
                                {...props}
                                iconBefore={icon}
                                ref={triggerRef}
                            />
                        )}
                    >
                        <DropdownItemGroup>
                            <div className='card-title-dropdown'><span>ALTERAR TIPO DO ITEM</span></div>
                            {items.filter(x => x.value !== cardType).map(item => (
                                <DropdownItem key={item.value} onClick={() => {
                                    this.props.onClick(item.value)
                                }}>
                                    {item.label}
                                </DropdownItem>
                            ))}
                        </DropdownItemGroup>
                    </DropdownMenu>
                </Tooltip>
                {cardKey && name && <Tooltip content={cardKey + ': ' + name} position='top'>
                    <div className='card-link-card'>
                        <Link to={"/card/" + cardKey + accessToken} target='_blank'>
                            {cardKey}
                        </Link>
                    </div>
                </Tooltip>}

            </div>
        )
    }
}
