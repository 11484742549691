import React, { Component } from 'react'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import Lozenge from '@atlaskit/lozenge';

function DropdownWorkPlanStatus(props) {
    let status = props.data?.find(x => x.type === props.status)
    if (!status) {
        return <></>
    }

    return (
        <DropdownMenu
            placement='bottom-start'
            trigger={({ triggerRef, testId, isSelected, ...props }) => (
                <Button
                    className={`btn-status atl-button ${status.color === 'in progress' ?
                        'btn-inProgress' : status.color === 'done' ? 'btn-done' : ''} ${props.spacing && 'card-status-big'}`}
                    {...props}
                    shouldFitContainer
                    iconAfter={<ChevronDownIcon />}
                    ref={triggerRef}
                    spacing={props.spacing ?? 'compact'}>
                    <span>{status.name}</span>
                </Button>
            )}>
            <DropdownItemGroup>
                {props.data?.filter(x => x.type !== props.status).map((item, index) => (
                    <DropdownItem key={`dropdownWorkPlanStatus-${index}`} onClick={() => props.onClick(item.type)}>
                        <Lozenge appearance={
                            item.color === 'in progress' ?
                                'inprogress' : item.color === 'done' ? 'success' : 'default'}>{item.name}</Lozenge>
                    </DropdownItem>
                ))}
            </DropdownItemGroup>
        </DropdownMenu>
    )
}
export default React.memo(DropdownWorkPlanStatus)
