import React from "react";
import Button from '@atlaskit/button';

export const MinziButton = React.memo(function MinziButton({
    appearance,
    className,
    onClick,
    iconBefore,
    iconAfter,
    children,
    style,
    isDisabled,
    isSelected,
    ref,
    shouldFitContainer
}) {
    return <Button ref={ref} shouldFitContainer={shouldFitContainer} isDisabled={isDisabled} isSelected={isSelected} appearance={appearance} className={`atl-button ${appearance} ${className}`}
        onClick={onClick}
        iconAfter={iconAfter}
        iconBefore={iconBefore} style={style}>
        {children}
    </Button>
})