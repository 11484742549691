import { del, get, post, put } from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/person`
}

export const List = () => {
    return get(`${getUrl()}/list`)
}


export const GlobalSearch = (q) => {
    return get(`${getUrl()}/globalSearch?q=` + q)
}

export const Me = () => {
    return get(`${getUrl()}/me`)
}

export const Menu = () => {
    return get(`${getUrl()}/menu`)
}

export const Config = () => {
    return get(`${getUrl()}/config`)
}

export const UpdateConfig = (config) => {
    return put(`${getUrl()}/config`, config)
}

export const AppLatestVersion = (platform) => {
    return get(`https://api-staging.minzi.com.br/api/person/app/${platform}/lastVersion`)
}

export const Permission = () => {
    return get(`${config.RouterUrl}/api/back/pessoa/permissao`)
}

export const ReleaseNotes = () => {
    return get(`${getUrl()}/releaseNotes`)
}

export const ReleaseNotesCheck = (code) => {
    return put(`${getUrl()}/releaseNotes/${code}`)
}

export const FavLinkList = () => {
    return get(`${getUrl()}/favlink`)
}

export const FavLinkUpdate = (obj) => {
    return post(`${getUrl()}/favlink`, obj)
}