import React from "react"
import * as Icon from "react-feather"
import { Cache } from "../views/pages/cache/cache"
const feather = require('feather-icons');

const navigationConfig = [
  {
    id: "sprintProject",
    title: "Kanban",
    type: "item",
    icon: <Icon.Columns size={18} />,
    permissions: ["D", 'A', "U", "G"],
    navLink: "/"
  },
  {
    id: "sprintProjectv2",
    title: "Kanban (ANTIGO)",
    type: "item",
    icon: <Icon.Columns size={18} />,
    permissions: ["D", 'A', "U", "G"],
    navLink: "/painel-old",
  },
  {
    id: "boardMine",
    title: "Backlog",
    type: "item",
    icon: <Icon.Layout size={18} />,
    permissions: ["D", 'A', "U", "G"],
    navLink: "/backlog"
  },
  {
    id: "minhasaprovacoes",
    title: "Aprovações",
    type: "item",
    icon: <Icon.AlertCircle size={18} />,
    permissions: ["D", 'A', "U", "G"],
    navLink: "/myapproval",
    badge: "warning",
    badgeText: "2",
  },
  {
    id: "trackingApp",
    title: "App",
    type: "item",
    icon: <Icon.DownloadCloud size={18} />,
    permissions: ["D", 'A', "U", "G"],
    navLink: "/download/app",
    badge: "info",
  },
  {
    id: "wiki",
    title: "Wiki",
    type: "item",
    icon: <Icon.FileText size={18} />,
    permissions: ["D", 'A', "U", "G"],
    navLink: "/wiki",
    badge: "info",
  },
  // {
  //   id: "wiki2",
  //   title: "Wiki2",
  //   type: "item",
  //   icon: <Icon.FileText size={18} />,
  //   permissions: ["D", 'A', "U", "G"],
  //   navLink: "/wiki2",
  //   badge: "info",
  // },
  {
    type: "groupHeader",
    groupTitle: "GESTÃO",
    permissions: ["D", "A", 'G', "U"]
  },
  {
    id: "sprintGlobal",
    title: "Sprints",
    type: "item",
    icon: <Icon.Columns size={18} />,
    permissions: ["D", 'A', 'G', "U"],
    navLink: "/management/sprint"
  },
  // {
  //   id: "sprintGlobal2",
  //   title: "Sprints2",
  //   type: "item",
  //   icon: <Icon.Columns size={18} />,
  //   permissions: ["D", 'A', 'G', "U"],
  //   navLink: "/management/sprint2"
  // },
  {
    id: "time",
    title: "Time",
    type: "item",
    icon: <Icon.User size={18} />,
    permissions: ["D", 'A', 'G'],
    navLink: "/management/team"
  },
  {
    id: "setores2",
    title: "Projetos",
    type: "item",
    icon: <Icon.FolderMinus size={18} />,
    permissions: ["D", 'A', 'G', 'U'],
    navLink: "/management/team/project"
  },
  {
    id: "costcenter",
    title: "Centro de custo",
    type: "item",
    icon: <Icon.DollarSign size={18} />,
    permissions: ["D", 'A', 'G'],
    navLink: "/management/v2/costcenter"
  },
  // {
  //   type: "groupHeader",
  //   groupTitle: "CADASTROS",
  //   permissions: ["D", "A", 'G']
  // },
  {
    id: "clients",
    title: "Empresas",
    type: "item",
    icon: <Icon.Box size={18} />,
    permissions: ["D", 'A'],
    navLink: "/management/clients"
  },
  {
    id: "rules",
    title: "Times",
    type: "item",
    icon: <Icon.Users size={18} />,
    permissions: ["D", "A", "G"],
    navLink: "/management/groups"
  },
  {
    type: "groupHeader",
    groupTitle: "APROVAÇÕES",
    permissions: ["D", "A", 'G']
  },
  {
    id: "aprovacoes",
    title: "Semana",
    type: "item",
    icon: <Icon.CheckSquare size={18} />,
    permissions: ["D", "A", 'G'],
    navLink: "/approvals/week"
  },
  /*{
    id: "paprovacoes",
    title: "Tempo",
    type: "item",
    icon: <Icon.UserCheck size={18} />,
    permissions: ["D", "A", 'G'],
    navLink: "/aprovacao"
  },*/
  {
    type: "groupHeader",
    groupTitle: "RELATÓRIOS",
    permissions: ["D", "A", 'G', 'U']
  },
  {
    id: "reportList",
    title: "Gerenciar",
    type: "item",
    icon: <Icon.Settings size={18} />,
    permissions: ["D", "A"],
    navLink: "/reports/manager"
  },
  {
    id: "favorites",
    title: "Relatórios",
    type: "collapse",
    icon: <span dangerouslySetInnerHTML={{ __html: feather.icons.list.toSvg({ width: 18, height: 18 }) }}></span>,
    permissions: ["D", "A", 'G', 'U'],
    //navLink: "/reports/all",
    children: [{
      id: "reportListAll",
      title: "Todos",
      type: "item",
      icon: <span dangerouslySetInnerHTML={{ __html: feather.icons.list.toSvg({ width: 10, height: 10 }) }}></span>,
      permissions: ["D", "A", 'G', 'U'],
      navLink: "/reports/all"
    }]
  },
  {
    id: "detalhado",
    title: "Detalhado",
    type: "item",
    icon: <Icon.Filter size={18} />,
    permissions: ["D", "A", 'G'],
    navLink: "/reports/times/manager"
  },
  // {
  //   id: "detalhado3",
  //   title: "Novo (EM DESENVOLVIMENTO)",
  //   type: "item",
  //   icon: <Icon.Filter size={18} />,
  //   permissions: ["D", "A", 'G'],
  //   navLink: "/reports/times/detail",
  //   users: Cache.userPermitedDocument
  // },
  {
    id: "detalhado2",
    title: "Detalhado",
    type: "item",
    icon: <Icon.Calendar size={18} />,
    permissions: ["U"],
    navLink: "/reports/times/collaborator"
  },
  /*{
    id: "reprojetos",
    title: "Projetos",
    type: "item",
    icon: <Icon.List size={18} />,
    permissions: ["D", "A", 'G'],
    navLink: "/relatorio/projetos"
  }*/
  {
    type: "groupHeader",
    groupTitle: "AJUDA",
    permissions: ["D", "A", 'G', 'U']
  },
  {
    id: "tutorial",
    title: "Tutorial",
    type: "item",
    icon: <Icon.HelpCircle size={18} />,
    permissions: ["D", 'A', 'G', 'U'],
    navLink: "/help"
  },
  // {
  //   id: "documents",
  //   title: "Release Notes",
  //   type: "item",
  //   icon: <Icon.HelpCircle size={18} />,
  //   permissions: ["D", 'A', 'G', 'U'],
  //   users: Cache.userPermitedDocument,
  //   navLink: "/documents"
  // },
  // {
  //   id: "spotlights",
  //   title: "Spotlights",
  //   type: "item",
  //   icon: <Icon.HelpCircle size={18} />,
  //   permissions: ["D", 'A', 'G', 'U'],
  //   users: Cache.userPermitedDocument,
  //   navLink: "/spotlights"
  // },
  // {
  //   id: "onboarding",
  //   title: "Onboardings",
  //   type: "item",
  //   icon: <Icon.HelpCircle size={18} />,
  //   permissions: ["D", 'A', 'G', 'U'],
  //   users: Cache.userPermitedDocument,
  //   navLink: "/onboarding"
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: "API",
  //   permissions: ["D", "A"]
  // },
  // {
  //   id: "token",
  //   title: "Token",
  //   type: "item",
  //   icon: <Icon.Code size={18} />,
  //   permissions: ["D", 'A'],
  //   navLink: "/api/token"
  // },
  // {
  //   id: "webhook",
  //   title: "Webhooks",
  //   type: "item",
  //   icon: <Icon.Code size={18} />,
  //   permissions: ["D", 'A'],
  //   navLink: "/api/webhook"
  // }
]

export default navigationConfig
