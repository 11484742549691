import React, { Component } from 'react'
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle'
import Button from '@atlaskit/button';
import {
    SpotlightPulse,
    SpotlightTarget,
} from '@atlaskit/onboarding';
import Tooltip from '@atlaskit/tooltip';
import { Cache } from '../cache/cache';

import miniLogo from './mini-logo.png'

export default class Onboarding extends Component {
    btn = () => {
        return null
        // return (
        //     <Tooltip content='Minzi Tour' position='bottom-end'>
        //         <Button style={{ marginLeft: '3px' }}
        //             className='atl-button'
        //             iconBefore={<img src={miniLogo} alt='Minzi Tour' width={35} />}
        //             onClick={this.props.onStart}
        //         />
        //     </Tooltip>
        // )
    }
    render() {
        const { spotlightTarget } = this.props
        const cache = Cache.omboarding.get()
        return (
            <SpotlightTarget name={spotlightTarget}>
                {!cache[spotlightTarget] && this.props.pulse ?
                    <SpotlightPulse pulse={50} radius={3} style={{ width: '48px', height: '32px' }} testId='pulse-suricatoo'>
                        {this.btn()}
                    </SpotlightPulse> :
                    this.btn()}
            </SpotlightTarget>
        )
    }
}
